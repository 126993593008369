import ThreedChart from '../3dchart/t3dchart'
import './Graph.css'

function Graph({nfcsession ,product}) {
  const type=localStorage.getItem("subtype");
  return (
    <div className={`graph-container ${type==='type2'?"":'blur-free-tier-content'}`}>
      <div className="graph-content">
        <ThreedChart  nfcsession={nfcsession}    product={product}/>
      </div>
    </div>
  )
}

export default Graph
