import React, { useState, useRef, useEffect } from "react";
import Code from "../../../assets/rename.png";
import DropArrow from "../../../assets/dropdown.png";
import "./Scans.css";

interface ScansProps {
  nfcsessions: any[]; // Adjust the type according to the shape of nfcsessions data
}

function Scans({ nfcsessions }: ScansProps) {
  type ScanType = "QR" | "RFID";

  const datamin = nfcsessions.map((item: any) => {
    const scans = item.openclose;
    return {
      scans,
    };
  });

  // Initialize counts
  let breakCount = 0;
  let unbreakCount = 0;
  let notAttTagCount = 0;

  // Loop through the data array
  datamin.forEach((item: any) => {
    const { scans } = item;

    // Increment the corresponding count based on the value of 'scans'
    switch (scans) {
      case "break":
        breakCount++;
        break;
      case "unbreak":
        unbreakCount++;
        break;
      case "not a TT tag":
        notAttTagCount++;
        break;
      // Add more cases if needed for other scan types
      default:
        break;
    }
  });

  const outputArray: any = [
    { scanType: "break", count: breakCount },
    { scanType: "unbreak", count: unbreakCount },
    { scanType: "not a TT tag", count: notAttTagCount },
    // Add more objects if needed for other scan types
  ];

  type ScanData = {
    [key in ScanType]: {
      sealedScans: number;
      unsealedScans: number;
    };
  };

  const scanData: ScanData = {
    RFID: {
      sealedScans: Number(breakCount),
      unsealedScans: Number(unbreakCount),
    },
    QR: { sealedScans: Number(notAttTagCount), unsealedScans: 200 },
  };
  var totalScans = breakCount + unbreakCount;
  const [scanType, setScanType] = useState("QR");
  const [showDropdown, setShowDropdown] = useState(false);
  const [sealedScans, setSealedScans] = useState(unbreakCount);
  const [unsealedScans, setUnsealedScans] = useState(breakCount);
  const [sealedScansper, setSealedScansper] = useState(
    (sealedScans / totalScans) * 100
  );
  const [unsealedScansper, setUnsealedScansper] = useState(
    (unsealedScans / totalScans) * 100
  );
  const [qrScans, setqrScans] = useState(notAttTagCount);
  const [qrScansper, setQrScansper] = useState(0);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setSealedScansper((sealedScans / totalScans) * 100);
    setUnsealedScansper((unsealedScans / totalScans) * 100);
    setQrScansper((notAttTagCount / totalScans) * 100);
  }, [nfcsessions, breakCount]); // Update unsealedScansper whenever nfcsessions or breakCount changes

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setShowDropdown(false);
    }
  };

  const selectOption = (value: ScanType) => {
    setScanType(value);
    setShowDropdown(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const perunbreakCountwidth = (unbreakCount / totalScans) * 100 || 0;
  const perbreakCountwidth = (breakCount / totalScans) * 100 || 0;
  const type = localStorage.getItem("subtype");
  const redirectToPricing = () => {
    if (type == "type2") {
    } else {
      // window.location.href = "/pricing";
    }
  };

  return (
    <div className="card card-two " onClick={() => redirectToPricing()}>
      <div className="scanner-btn-align">
        <div className="top-container">
          <img src={Code} alt="Scan icon" className="total-scans-icon" />
          <div className="total-scans-label">Scan Type</div>
        </div>
        <div className="dropdown" ref={dropdownRef}>
          <button
            className="dropdown-button"
            onClick={() => setShowDropdown(!showDropdown)}
          >
            {scanType} <img src={DropArrow} alt="Dropdown arrow" />
          </button>
          {showDropdown && (
            <div className="dropdown-content">
              <button onClick={() => selectOption("QR")}>QR</button>
              <button onClick={() => selectOption("RFID")}>RFID</button>
            </div>
          )}
        </div>
      </div>
      {scanType === "RFID" ? (
        <div
          className={`scanner-bottom-container ${
            type === "type2" ? "" : "blur-free-tier-content"
          }`}
        >
          <div className="scan-category">
            <div className="scan-info">
              Sealed Scans{" "}
              <span className="scan-number">{Number(unbreakCount)}</span>
            </div>
            <div className="scan-bar-container">
              <div
                className="scan-bar"
                style={{
                  width: `${perunbreakCountwidth}%`,
                  backgroundColor: "#119927",
                  height: `12px`,
                }}
              ></div>
            </div>
          </div>
          <div className="scan-category">
            <div className="scan-info">
              Unsealed Scans <span className="scan-number">{breakCount}</span>
            </div>
            <div className="scan-bar-container">
              <div
                className="scan-bar"
                style={{
                  width: `${perbreakCountwidth}%`,
                  backgroundColor: "#EA6708",
                  height: `12px`,
                }}
              ></div>
            </div>
          </div>
        </div>
      ) : (
        <div className="scanner-bottom-container ">
          <div className="scan-category">
            <div className="scan-info">
              Total Scans <span className="scan-number">{notAttTagCount}</span>
            </div>
            <div className="scan-bar-container">
              <div
                className="scan-bar"
                style={{
                  width: `${(notAttTagCount / totalScans) * 100}%`,
                  backgroundColor: "#119927",
                  height: `12px`,
                }}
              ></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Scans;
