import React, { useState, useEffect } from "react";
import "./CustomButtons.css";
import SvgIcon from "./SvgIcon";
import activegaugeImg from "../../assets/Gauge.png";
import activebarcodeImg from "../../assets/Barcode.png";
import activereceiptImg from "../../assets/Receipt.png";
import activeusersThreeImg from "../../assets/UsersThree.png";
import gaugeImg from "../../assets/Gaugeactive.png";
import barcodeImg from "../../assets/Barcodeactive.png";
import receiptImg from "../../assets/Receiptactive.png";
import usersThreeImg from "../../assets/UsersThreeactive.png";
import settingsImg from "../../assets/settings.png";
import profileImg from "../../assets/profile.png";
import activeProfileImg from "../../assets/profilewhite.png";
import activesettingsImg from "../../assets/settingsactive.png";
import { useNavigate, useLocation } from "react-router-dom";

interface ButtonItem {
  text: string;
}

const buttonItems: ButtonItem[] = [
  { text: "Dashboard" },
  { text: "Product" },
  { text: "Orders" },
  { text: "Profile" },
  { text: "Settings" },
];

const buttonItems1: ButtonItem[] = [
  { text: "Dashboard" },
  { text: "Reviews" },
  { text: "Orders" },
  { text: "Profile" },
  { text: "Settings" },
];

const initialActiveStates = {
  Dashboard: true,
  Product: false,
  Orders: false,
  Profile: false,
  Settings: false,
};

const CustomButtons: React.FC<{ setSidebarOpen: (open: boolean) => void }> = ({
  setSidebarOpen,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [activeStates, setActiveStates] = useState(initialActiveStates);
  const tenant = (localStorage.getItem("tenant") || "").toLowerCase();

  useEffect(() => {
    const currentPath = location.pathname.slice(1).toLowerCase();
    setActiveStates((prevStates) => ({
      ...prevStates,
      Dashboard: currentPath === "dashboard" || currentPath === "/",
      Product: currentPath === "product",
      Orders: currentPath === "orders",
      Profile: currentPath === "profile",
      Settings: currentPath === "settings",
    }));
  }, [location.pathname]);

  const handleButtonClick = (text: string) => {
    setActiveStates((prevStates) => ({
      ...prevStates,
      Dashboard: text === "Dashboard",
      Product: text === "Product",
      Orders: text === "Orders",
      Profile: text === "Profile",
      Settings: text === "Settings",
    }));

    let path = text.toLowerCase();

    if (text === "reviews") {
      // Navigate to the reviews page with the ID as a query parameter
      navigate(`/reviews`);
    }else {
      // For other paths, use the standard navigation
      path = `/${path}`;
      navigate(path);
    }


    navigate(path); // Navigate to the new path.
    setSidebarOpen(false); // Close the sidebar.
  };

  const getImageForButton = (text: string) => {
    switch (text) {
      case "Dashboard":
        return activeStates.Dashboard ? activegaugeImg : gaugeImg;
      case "Product":
        return activeStates.Product ? activebarcodeImg : barcodeImg;
      case "Orders":
        return activeStates.Orders ? activereceiptImg : receiptImg;
      case "Profile":
        return activeStates.Profile ? activeProfileImg : profileImg;
      case "Settings":
        return activeStates.Settings ? activesettingsImg : settingsImg;
      default:
        return gaugeImg;
    }
  };
  const getImageForButtongme = (text: string) => {
    switch (text) {
      case "Dashboard":
        return activeStates.Dashboard ? activegaugeImg : gaugeImg;
      case "Reviews":
        return activeStates.Product ? activebarcodeImg : barcodeImg;
      case "Orders":
        return activeStates.Orders ? receiptImg : receiptImg;
      case "Profile":
        return activeStates.Profile ? activeProfileImg : profileImg;
      case "Settings":
        return activeStates.Settings ? activesettingsImg : settingsImg;
      default:
        return gaugeImg;
    }
  };

  const getActiveState = (
    key: string,
    activeStates: typeof initialActiveStates
  ) => {
    return activeStates[key as keyof typeof initialActiveStates];
  };

  return (
    <div className="custom-btn">
      {tenant == "minedrill1" ? null : tenant=="globalminingequipments1" || tenant=='gme1'?(
        <div className="custom-buttons-container">
          <SvgIcon
            images={buttonItems1.map((item) => getImageForButtongme(item.text))}
            activeStates={buttonItems1.map((item) =>
              getActiveState(item.text, activeStates)
            )}
            onIconClick={handleButtonClick}
            buttonTexts={buttonItems1.map((item) => item.text)}
          />
          <div className="buttons-text-container">
            {buttonItems1.map((item, index) => (
              <div
                key={index}
                className="button-text-item"
                onClick={() => handleButtonClick(item.text)}
              >
                <span
                  className={`button-text ${
                    getActiveState(item.text, activeStates) ? "active" : ""
                  }`}
                >
                  {item.text}
                </span>
              </div>
            ))}
          </div>
        </div>
      ):(
        <div className="custom-buttons-container">
          <SvgIcon
            images={buttonItems.map((item) => getImageForButton(item.text))}
            activeStates={buttonItems.map((item) =>
              getActiveState(item.text, activeStates)
            )}
            onIconClick={handleButtonClick}
            buttonTexts={buttonItems.map((item) => item.text)}
          />
          <div className="buttons-text-container">
            {buttonItems.map((item, index) => (
              <div
                key={index}
                className="button-text-item"
                onClick={() => handleButtonClick(item.text)}
              >
                <span
                  className={`button-text ${
                    getActiveState(item.text, activeStates) ? "active" : ""
                  }`}
                >
                  {item.text}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomButtons;
