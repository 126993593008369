import { CognitoUserPool, CognitoUser, AuthenticationDetails, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import config from './config';
import CryptoJS from 'crypto-js';

const userPool = new CognitoUserPool({
  UserPoolId: config.userPoolId,
  ClientId: config.userPoolWebClientId,
});

function generateSecretHash(username) {
    const hash = CryptoJS.HmacSHA256(username + config.userPoolWebClientId, config.clientSecret);
    const secretHash = CryptoJS.enc.Base64.stringify(hash);
    return secretHash;
}

export function signUp(username, password, email, brandName) {
    return new Promise((resolve, reject) => {
        const attributeList = [
            new CognitoUserAttribute({
                Name: 'name',
                Value: brandName,
            }),
        ];
        const Username = email;
        const secretHash = generateSecretHash(username);

        userPool.signUp(Username, password, attributeList, null, (err, result) => {
            if (err) {
                reject(err);
            } else {
                resolve(result);
            }
        });
    });
}

export function signIn(username, password) {
    return new Promise((resolve, reject) => {
        const user = new CognitoUser({
            Username: username,
            Pool: userPool,
        });

        const authDetails = new AuthenticationDetails({
            Username: username,
            Password: password,
            // SecretHash: generateSecretHash(username),
        });

        user.authenticateUser(authDetails, {
            onSuccess: async (result) => {
                const authToken = result.getIdToken().getJwtToken();
                const accessToken = result.getAccessToken().getJwtToken();
                const refreshToken = result.getRefreshToken().getToken();
                const signInTime = result.getIdToken().payload.auth_time;
                const time_signin= new Date(result.getIdToken().payload.auth_time * 1000).toISOString();
                localStorage.setItem('authToken', authToken);
                localStorage.setItem('user', JSON.stringify(result));
                const sessionStartTime = Date.now();

                // Save session to DynamoDB via Lambda
                // const sessionDetails = {
                //     username,
                //     signInTime,
                //     sessionStartTime,
                //     time_signin,
                    
                // };

                // try {
                //     await fetch('https://lurt43xrql.execute-api.ap-south-1.amazonaws.com/prod/sessions', {
                //         method: 'POST',
                //         headers: {
                //             'Content-Type': 'application/json',
                //         },
                //         body: JSON.stringify(sessionDetails),
                //     });

                //     console.log('Session saved to DynamoDB');
                // } catch (error) {
                //     console.error('Error saving session to DynamoDB:', error);
                // }

                resolve(result);
            },
            onFailure: (err) => {
                reject(err);
            },
        });
    });
}

export function signOut() {
    const user = userPool.getCurrentUser();
    if (user) {
        user.signOut();
    }
}

export function getCurrentUser() {
    return userPool.getCurrentUser();
}

export function getSession() {
    return new Promise((resolve, reject) => {
        const user = getCurrentUser();
        if (!user) {
            reject('No user is logged in');
            return;
        }
        user.getSession((err, session) => {
            if (err) {
                reject(err);
                return;
            }
            resolve(session);
        });
    });
}

export function changePassword(oldPassword, newPassword) {
    return new Promise((resolve, reject) => {
        const user = getCurrentUser();
        if (!user) {
            reject('No user is logged in');
            return;
        }
        user.getSession((err, session) => {
            if (err) {
                reject(err);
                return;
            }
            user.changePassword(oldPassword, newPassword, (err, result) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(result);
                }
            });
        });
    });
}

export function forgotPassword(username) {
    return new Promise((resolve, reject) => {
        const userData = {
            Username: username,
            Pool: userPool,
        };
        const user = new CognitoUser(userData);
        user.forgotPassword({
            onSuccess: (result) => resolve(result),
            onFailure: (err) => reject(err),
        });
    });
}

export function confirmForgotPassword(username, verificationCode, newPassword) {
    return new Promise((resolve, reject) => {
        const userData = {
            Username: username,
            Pool: userPool,
        };
        const user = new CognitoUser(userData);
        user.confirmPassword(verificationCode, newPassword, {
            onSuccess: () => resolve(),
            onFailure: (err) => reject(err),
        });
    });
}
