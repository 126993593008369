import React, { useEffect, useState } from "react";
import "./Info.css";
import Search from "./search/Search";
import Profile from "./profile/Profile";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import wallet from "../../assets/wallet-2.png"
import axios from 'axios';
import { signIn, signOut } from "../../cognito/Auth";
import Notification from "./notification/Notification";
import {
  fetchNfcSessions,
  fetchProducts,
  setcredits,
} from "../../redux/counterSlice";
import logout from '../../assets/Logout.png';

function Info() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const credits = useSelector((state) => state.counter.credits);

  function formatNumber(number) {
    if (number >= 1000 && number < 10000) {
      return (number / 1000).toFixed(1) + 'k';
    } else if (number >= 10000) {
      return (number / 1000).toFixed(0) + 'k';
    } else {
      return number.toFixed(1);
    }
  }

  const handlelogout = async () => {
    localStorage.setItem("tenantdata", " ");
    localStorage.setItem("tenant", " ");
    await signOut();
    navigate("/");
  };

  const confirmLogout = () => {
    if (window.confirm("Are you sure you want to logout?")) {
      handlelogout();
    }
  };

  const dispatch = useDispatch();
  const tenentData = useSelector((state) => state.counter.tenentData);
  const tenantObj = useSelector((state) => state.counter.tenantObject);

  useEffect(() => {
    const tenant = localStorage.getItem("tenant").toLowerCase();
    const selectedTenant = tenentData.find(
      (order) => order.employeeid === tenant
    );
    // dispatch(setcredits(selectedTenant.credits));
  }, [tenantObj]);

  const [selectedTenant, setSelectedTenant] = useState({});
  const tenant = localStorage.getItem("tenant").toLowerCase();

  useEffect(() => {
    const fetchData = async () => {
      const tenant = localStorage.getItem("tenant").toLowerCase();
      const postdata = {
        employeeid: tenant
      };

      try {
        const response = await axios.post(
          "https://x5i01p9a23.execute-api.ap-south-1.amazonaws.com/prod/credits",
          JSON.stringify(postdata),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        dispatch(setcredits(response.data.credits));
        setSelectedTenant(response.data);
      } catch (error) {
        console.error("Error fetching NfcSessions:", error);
      }
    };
    fetchData();
  }, [credits]);

  const renderNotification = location.pathname === "/dashboard" || location.pathname === "/";

  return (
    <div className="info-container">
      {tenant=="globalminingequipments1" || tenant=='gme1'?
      null:<div style={{display:"flex", alignItems:"center", flexDirection:"row", gap:"15px"}}>
      <Search />
      <Profile />

      {renderNotification && <Notification />}
      <div className="credits-container">
        <div 
          className="credits-button"
          // onClick={() => navigate('/pricing')}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "linear-gradient(92.49deg, #0956CC 0%, #1A4E9F 100%)",
            boxShadow:
              "4px 4px 16px 0px #FFFFFF33 inset, -4px -4px 16px 0px #00000040 inset",
            color: "white",
            fontSize: "12px",
            fontWeight: "300",
            borderRadius: "20px",
            fontFamily: "Roboto",
            margin: "5px",
            cursor: "pointer",
            overflow: "hidden",
            height: "40px",
          }}
        >
          &nbsp;
          <div style={{ display: "flex", alignItems: "center" }} >
            <p style={{ fontSize: "16px", color:"white", fontWeight: "bold" }}>Credits:</p>
          </div>
          <div style={{ fontSize: "16px", fontWeight: "bold" }}>{credits.toFixed(0)}</div>
          &nbsp;
         <img src={wallet} 
         style={{height:'25px', width:'25px'}}/>
          &nbsp;
        </div>
      </div>
      {/* <div>
        <div className="icon-circle">
          <img onClick={confirmLogout} src={logout} alt="Logout" className='img-info' />
        </div>
      </div> */}
      </div>
    }
    </div>
    
  );
}

export default Info;
