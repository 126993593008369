import React, { useState, useEffect } from "react";
import axios from "axios";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { format } from "date-fns"; // To format date
import "./CINSelector.css";
import JSZip from "jszip";

const CINSelector = () => {
  const [cinList, setCinList] = useState([]);
  const [uniqueRefNos, setUniqueRefNos] = useState([]);
  const [filteredCinList, setFilteredCinList] = useState([]);
  const [selectedRefNo, setSelectedRefNo] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [refPartNo, setRefPartNo] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [quantity, setQuantity] = useState("");
  const [fileType, setFileType] = useState("Notepad");
  const [isDownloadEnabled, setIsDownloadEnabled] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isBatchPrinting, setIsBatchPrinting] = useState(false);
  const [batchPrintQty, setBatchPrintQty] = useState("");

  useEffect(() => {
    const fetchCinList = async () => {
      try {
        const response = await axios.get(
          "https://go83cmp8wk.execute-api.ap-south-1.amazonaws.com/dev/records"
        );
        setCinList(response.data);

        const uniqueRefs = [...new Set(response.data.map((cin) => cin.RefNo))];
        setUniqueRefNos(uniqueRefs);
        setFilteredCinList(uniqueRefs);
        console.log(response.data)
      } catch (error) {
        console.error("Error fetching CIN list:", error);
      }
    };
    fetchCinList();
  }, []);

  const handleFilterChange = (event) => {
    const filterText = event.target.value;
    setSelectedRefNo(filterText);
    setShowDropdown(true);

    const filtered = uniqueRefNos.filter((refNo) =>
      refNo ? refNo.toLowerCase().includes(filterText.toLowerCase()) : false
    );
    setFilteredCinList(filtered);
  };

  const handleRefNoSelect = (refNo) => {
    setSelectedRefNo(refNo.replace(/\//g, "-"));
    setShowDropdown(false);
    const items = cinList
      .filter((cin) => cin.RefNo === refNo)
      .map((cin) => cin.Item);
    setFilteredItems([...new Set(items)]);
    setSelectedItem("");
    setQuantity("");
    setIsDownloadEnabled(false);
  };

const handleItemSelect = (item) => {
    setSelectedItem(item);
    setQuantity("");
    setIsDownloadEnabled(false);

    console.log(cinList, selectedRefNo, item);

    // Normalize RefNo format for comparison
    const normalizedSelectedRefNo = selectedRefNo.replace(/-/g, "/");

    // Fetch Ref Part No for the selected item
    const selectedCin = cinList.find(
        (cin) => cin.RefNo === normalizedSelectedRefNo && cin.Item === item
    );

    console.log(cinList, normalizedSelectedRefNo, item, selectedCin);

    if (selectedCin) {
        setRefPartNo(selectedCin["Ref Part No"]);
    } else {
        setRefPartNo("");
    }
};



  const handleQuantityChange = (event) => {
    const value = event.target.value;
  
    // Allow empty string for clearing the input
    if (value === "") {
      setQuantity("");
      setIsDownloadEnabled(false);
    } else {
      const numericValue = Math.min(Number(value), 10000);
      setQuantity(numericValue);
      setIsDownloadEnabled(numericValue > 0);
    }
  };
  

  const handleBatchPrintChange = (event) => {
    setIsBatchPrinting(event.target.checked);
    setBatchPrintQty(""); // Reset batch quantity when toggling the checkbox
  };

  const handleBatchPrintQtyChange = (event) => {
    const value = event.target.value;
    if (value <= quantity) {
      setBatchPrintQty(value);
    } else {
      alert("Batch quantity cannot be greater than the total quantity.");
    }
  };

  const handleFileTypeChange = (event) => {
    setFileType(event.target.value);
  };

  const generateHexValues = (count) => {
    const hexValues = new Set();
    while (hexValues.size < count) {
      const randomID = Math.random().toString(36).substr(2, 6).toUpperCase();
      hexValues.add(randomID);
    }
    return Array.from(hexValues);
  };

const handleDownload = async () => {

  if (!refPartNo) {
    alert("Please enter or select a Ref Part No before proceeding.");
    return; // Exit the function if refPartNo is not provided
}
    const hexValues = generateHexValues(Number(quantity));
    const currentCombination = {
        refNo: selectedRefNo,
        item: selectedItem,
        qty: quantity,
    };

    const existingData = JSON.parse(localStorage.getItem("downloadedFiles")) || [];
    const isAlreadyDownloaded = existingData.some(
        (entry) =>
            entry.refNo === currentCombination.refNo &&
            entry.item === currentCombination.item &&
            entry.qty === currentCombination.qty
    );

    if (isAlreadyDownloaded) {
        const userChoice = window.confirm(
            "This combination of Ref No, Item, and Qty has already been downloaded. Do you want to continue?"
        );
        if (!userChoice) return;
    }

    try {
        const zip = new JSZip();
        const currentDate = format(new Date(), "yyyy-MM-dd");
        const folderName = `${selectedRefNo}-${currentDate}`;
        const folder = zip.folder(folderName);

        if (isBatchPrinting && batchPrintQty > 0) {
            const batchSize = Math.ceil(hexValues.length / batchPrintQty);
            let batchIndex = 1;

            for (let i = 0; i < hexValues.length; i += batchSize) {
                const batchData = hexValues.slice(i, i + batchSize);
                const batchFileName = `${folderName}-batch${batchIndex}.txt`;
                const refPartFileName = `${selectedItem}-RefPartNo-batch${batchIndex}.txt`;

                if (fileType === "Notepad") {
                    // Main file with hex values
                    folder.file(batchFileName, batchData.join("\n"));

                    // Ref Part No file repeated for the same count
                    folder.file(refPartFileName, Array(batchData.length).fill(refPartNo).join("\n"));
                } else if (fileType === "Excel") {
                    // Main file with hex values
                    const worksheet = XLSX.utils.json_to_sheet(
                        batchData.map((value) => ({ Serial: value }))
                    );
                    const workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workbook, worksheet, "Serial Numbers");

                    const excelBlob = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
                    folder.file(batchFileName, excelBlob);

                    // Ref Part No file repeated for the same count
                    const refWorksheet = XLSX.utils.json_to_sheet(
                        Array(batchData.length).fill(refPartNo).map((value) => ({ RefPartNo: value }))
                    );
                    const refWorkbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(refWorkbook, refWorksheet, "Ref Part Numbers");

                    const refExcelBlob = XLSX.write(refWorkbook, { bookType: "xlsx", type: "array" });
                    folder.file(refPartFileName, refExcelBlob);
                }

                batchIndex++;
            }
        } else {
            const fileName = `${folderName}.txt`;
            const refPartFileName = `${selectedItem}-RefPartNo.txt`;

            if (fileType === "Notepad") {
                // Main file with hex values
                folder.file(fileName, hexValues.join("\n"));

                // Ref Part No file repeated for the same count
                folder.file(refPartFileName, Array(hexValues.length).fill(refPartNo).join("\n"));
            } else if (fileType === "Excel") {
                // Main file with hex values
                const worksheet = XLSX.utils.json_to_sheet(
                    hexValues.map((value) => ({ Serial: value }))
                );
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "Serial Numbers");

                const excelBlob = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
                folder.file(fileName, excelBlob);

                // Ref Part No file repeated for the same count
                const refWorksheet = XLSX.utils.json_to_sheet(
                    Array(hexValues.length).fill(refPartNo).map((value) => ({ RefPartNo: value }))
                );
                const refWorkbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(refWorkbook, refWorksheet, "Ref Part Numbers");

                const refExcelBlob = XLSX.write(refWorkbook, { bookType: "xlsx", type: "array" });
                folder.file(refPartFileName, refExcelBlob);
            }
        }

        // Generate ZIP file and download
        const zipBlob = await zip.generateAsync({ type: "blob" });
        saveAs(zipBlob, `${folderName}.zip`);

        const apiBody = {
            chalanumber: selectedRefNo,
            ids: hexValues,
            item: selectedItem,
        };

        await axios.post(
            "https://go83cmp8wk.execute-api.ap-south-1.amazonaws.com/dev/gmeuids",
            apiBody
        );

        localStorage.setItem(
            "downloadedFiles",
            JSON.stringify([...existingData, currentCombination])
        );

        const orderDetailsBody = {
            orderDetails: `${selectedRefNo}-${selectedItem}`,
            qty: quantity,
            status: "Downloaded",
        };

        await axios.post(
            "https://go83cmp8wk.execute-api.ap-south-1.amazonaws.com/dev/gme-orders",
            orderDetailsBody
        );

        alert("File downloaded and data successfully posted to both APIs.");
    } catch (error) {
        console.error("Error during file download or API request:", error);
        alert("An error occurred. Please try again.");
    }
};

  

  const downloadButtonStyles = {
    background: "linear-gradient(92.49deg, #0956CC 0%, #1A4E9F 100%)",
    boxShadow:
      "4px 4px 16px 0px #FFFFFF33 inset, -4px -4px 16px 0px #00000040 inset, 0px 4px 4px 0px #00000040",
    color: "white",
    padding: "10px 20px",
    fontSize: "16px",
    border: "none",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    height: "40px",
    borderRadius: "24px",
    fontFamily: "'Roboto', sans-serif",
    transition: "background-color 0.3s ease",
  };

  return (
    <div style={{ height: "100%", padding: "20px" }}>
      <div className="cin-selector-container">
        <h2 className="cin-selector-title" style={{ marginBottom: "20px" }}>
          Challan Identification Number (CIN)
        </h2>

        <div className="cin-selector-dropdown-container" style={{ marginBottom: "20px" }}>
          <div className="cin-selector-dropdown">
            <label className="cin-selector-label">Ref No:</label>
            <input
              type="text"
              placeholder="Type to filter RefNo"
              value={selectedRefNo}
              onChange={handleFilterChange}
              onFocus={() => setShowDropdown(true)}
              className="cin-selector-input"
              style={{ marginBottom: "10px" }}
            />
            {showDropdown && filteredCinList.length > 0 && (
              <div className="cin-selector-dropdown-menu">
                {filteredCinList.map((refNo) => (
                  <div
                    key={refNo}
                    className="cin-selector-dropdown-item"
                    onClick={() => handleRefNoSelect(refNo)}
                  >
                    {refNo}
                  </div>
                ))}
              </div>
            )}
          </div>

          {selectedRefNo && (
            <div className="cin-selector-dropdown">
              <label className="cin-selector-label">Item:</label>
              <select
                value={selectedItem}
                onChange={(e) => handleItemSelect(e.target.value)}
                className="cin-selector-select"
                style={{ marginBottom: "20px" }}
              >
                <option value="">Select Item</option>
                {filteredItems.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>


        {refPartNo && (
    <div className="cin-selector-ref-part-no" style={{ marginBottom: "20px" }}>
        <label className="cin-selector-label">Ref Part No:</label>
        <span>{refPartNo}</span>
    </div>
)}


        {selectedItem && (
          <>
            <div className="cin-selector-quantity-input" style={{ marginBottom: "20px" }}>
              <label className="cin-selector-label">Enter Qty (Max: 10,000):</label>
              <input
                type="number"
                value={quantity}
                onChange={handleQuantityChange}
                min="1"
                max="10000"
                className="cin-selector-input"
                style={{ marginBottom: "10px" }}
              />
            </div>

            <div className="cin-selector-batch-input" style={{ marginBottom: "20px" }}>
              <label className="cin-selector-label">Enable Batch Printing:</label>
              <input
                type="checkbox"
                checked={isBatchPrinting}
                onChange={handleBatchPrintChange}
                style={{ marginLeft: "10px", marginBottom: "10px" }}
              />
              {isBatchPrinting && (
                <div className="cin-selector-batch-qty">
                  <label className="cin-selector-label">Batch Qty:</label>
                  <input
                    type="number"
                    value={batchPrintQty}
                    onChange={handleBatchPrintQtyChange}
                    min="1"
                    max={quantity}
                    className="cin-selector-input"
                    style={{ marginBottom: "10px" }}
                  />
                </div>
              )}
            </div>

            <div className="cin-selector-filetype-input" style={{ marginBottom: "20px" }}>
              <label className="cin-selector-label">Select File Type:</label>
              <select
                value={fileType}
                onChange={handleFileTypeChange}
                className="cin-selector-select"
                style={{ marginBottom: "10px" }}
              >
                <option value="Notepad">Notepad</option>
                <option value="Excel">Excel</option>
              </select>
            </div>
          </>
        )}

        {isDownloadEnabled && (
          <button onClick={handleDownload} style={downloadButtonStyles}>
            Download Codes
          </button>
        )}
      </div>
    </div>
  );
};

export default CINSelector;
