import React, { useEffect, useState } from "react";
import "./Cards.css";
import arrowImage from "../../../assets/arrowleft.png";
import scan from "../../../assets/barcode-scan.png";
import warning from "../../../assets/Warning.png";
import customers from "../../../assets/group.png";
import Scans from "./scantype/Scansgme";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Cardsgme({ nfcsessions, selectedtenant, selectedOptions }) {
  const navigation = useNavigate();
  const [avg, setavg] = useState(0);
  const [lastUpload, setLastUpload] = useState("No uploads yet");

  const tenant = localStorage.getItem("tenant")?.toLocaleLowerCase();
  const uploadTimestamp = localStorage.getItem("AICgmeupload");

  function calculateIncreasePercentage(a, b) {
    const percentageIncrease = ((Number(b) - a) / a) * 100;
    return isNaN(percentageIncrease) || !isFinite(percentageIncrease)
      ? 0
      : percentageIncrease;
  }

  function getRelativeTimeDescription(timestamp) {
    const uploadDate = new Date(parseInt(timestamp));
    const today = new Date();
    const differenceInDays = Math.floor(
      (today - uploadDate) / (1000 * 60 * 60 * 24)
    );

    if (differenceInDays === 0) return "Uploaded today";
    if (differenceInDays === 1) return "Uploaded yesterday";
    return `Uploaded ${differenceInDays} days ago`;
  }

  useEffect(() => {
    // Calculate average occurrences
    const ipCounts = nfcsessions.reduce((counts, { geodata }) => {
      counts[geodata.ip] = (counts[geodata.ip] || 0) + 1;
      return counts;
    }, {});

    const totalOccurrences = Object.values(ipCounts).reduce(
      (sum, count) => sum + count,
      0
    );
    const totalUniqueIPs = Object.keys(ipCounts).length;
    setavg(totalUniqueIPs > 0 ? totalOccurrences / totalUniqueIPs : 0);

    // Calculate the last upload relative time
    if (uploadTimestamp) {
      const relativeTime = getRelativeTimeDescription(uploadTimestamp);
      setLastUpload(relativeTime);

      // Save the formatted date in local storage
      localStorage.setItem("formattedLastUpload", relativeTime);
    }
  }, [nfcsessions, uploadTimestamp]);

  const [increasePercentageinscan, setincreasePercentageinscan] = useState(1);
  const [increasePercentageinavgscan, setincreasePercentageinavgscan] =
    useState(1);

  // Rest of the logic remains the same...

  const redirectToPricing = () => {
    const type = localStorage.getItem("subtype");
    if (type === "type2") {
      // Custom logic for type2
    } else {
      // window.location.href = "/pricing";
    }
  };

  const type = localStorage.getItem("subtype");
  const [CounterfeitCount, setCounterfeitCount] = useState(0);
  const [CounterfeitData, setCounterfeitData] = useState([]);
  useEffect(() => {
    const fetchCounterfeitData = async () => {
      try {
        const response = await axios.get(
          "https://go83cmp8wk.execute-api.ap-south-1.amazonaws.com/dev/gmelambdascan"
        );
        if (response.status === 200) {
          const data = response.data;
          setCounterfeitData(data);
          const count = data.filter((row) => row.numberofscan >= 10).length;
          setCounterfeitCount(count);
        }
      } catch (error) {
        console.error("Error fetching counterfeit data:", error);
      }
    };

    fetchCounterfeitData();
  }, [nfcsessions]);
  return (
    <div className="cards-container">
      <div className="card card-one">
        <div className="top-container">
          <img src={scan} alt="Scan icon" className="total-scans-icon" />
          <div className="total-scans-label">Total Scans</div>
        </div>
        <div className="bottom-container">
          <div className="total-scans-number">{nfcsessions.length}</div>
          <div className="percentage-change-container">
            <div className="percentage-change">
              +{increasePercentageinscan.toFixed(1)}%
            </div>
            <img src={arrowImage} alt="Arrow" className="arrow-image" />
            <div className="from-yesterday-label">from yesterday</div>
          </div>
        </div>
      </div>

      <Scans nfcsessions={nfcsessions} />

      <div className="card card-three">
        <div className="top-container">
          <img src={customers} alt="Scan icon" className="total-scans-icon" />
          <div className="total-scans-label">Supply Chain File Update</div>
        </div>
        <div className="bottom-container" style={{ textAlign: "center" }}>
          <div
            className="upload-label"
            style={{
              fontSize: "14px",
              color: "#555",
              marginBottom: "5px",
            }}
          >
            <span style={{ fontWeight: "bold" }}>Last upload date:</span>
          </div>
          <div
            className="upload-date"
            style={{
              fontSize: "16px",
              color: "#333",
              fontWeight: "bold",
            }}
          >
            {new Date(parseInt(uploadTimestamp)).toLocaleDateString()}{" "}
            {/* Formatted date */}
          </div>
          <div
            className="upload-relative-time"
            style={{
              fontSize: "14px",
              color: "#777",
              marginTop: "5px",
            }}
          >
            {lastUpload} {/* Relative time */}
          </div>
        </div>
      </div>

      <div className="card card-four " onClick={() => redirectToPricing()}>
        <div className="top-container">
          <img src={warning} alt="Scan icon" className="total-scans-icon" />
          <div className="total-scans-label">Counterfeit Reports</div>
        </div>
        <div
          className={`bottom-container ${
            type === "type2" ? "" : "blur-free-tier-content"
          }`}
        >
          <div className="total-scans-number">{CounterfeitCount}</div>
          <div className="bottom-container">
            <div
              className="percentage-change-container"
              onClick={() => navigation("/GME-counterfeits")}
            >
              <img src={arrowImage} alt="Arrow" className="arrow-image" />
              <div className="from-yesterday-label">
                Check Counterfeit Reports
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cardsgme;
