import React, { useState, useEffect, useRef } from "react";
import "./Order.css";
import ReloadIcon from "../assets/reload.png";
import filter from "../assets/filter.png";
import DownArrow from "../assets/dropdownblue.png";
import Status from "./Status/Status";
import axios from "axios";
import { format } from "date-fns";
import { QRCode } from "react-qrcode-logo";
import { fetchTenantData } from "../redux/counterSlice";
import { useDispatch } from "react-redux";

function getStatusStyle(status) {
  const baseStyle = {
    borderRadius: "8px",
    padding: "4px 15px",
    fontFamily: "Roboto",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "18.75px",
    letterSpacing: "0.02em",
  };
  const styles = {
    pending: {
      backgroundColor: "#FFF1E6",
      color: "#EA6708",
      ...baseStyle,
    },
    Picked: {
      backgroundColor: "#FFF1E6",
      color: "#EA6708",
      ...baseStyle,
    },
    Transit: {
      backgroundColor: "#b3cee5",
      color: "#4682B4",
      ...baseStyle,
    },
    Delivered: {
      backgroundColor: "#DDFFE2",
      color: "#119927",
      ...baseStyle,
    },
    Downloaded: {
      backgroundColor: "#E6F0FF",
      color: "#0D6EFD",
      ...baseStyle,
    },
  };

  return <span style={styles[status]}>{status}</span>;
}

function Order() {
  const [expandedRow, setExpandedRow] = useState(null);
  const [isRotating, setIsRotating] = useState(false);
  const [open, setOpen] = useState(false);
  const [tenantData, setTenantData] = useState([]);
  const tenantDataRef = useRef([]);
  const [logoImage, setLogoImage] = useState("");
  const [logoOpacity, setLogoOpacity] = useState(1);
  const [qrStyle, setQRStyle] = useState("dots");
  const [logoSize, setLogoSize] = useState(40);

  const dispatch = useDispatch();

  const reloadData = () => {
    setIsRotating(true);
    setTimeout(() => {
      setIsRotating(false);
    }, 2000);
  };

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("user"));
    dispatch(fetchTenantData(data.idToken.payload.name));
  }, []);

  const [url, setUrl] = useState();
  const handleClose = (a) => {
    setUrl(a);
    setOpen(!open);
  };
  const toggleRow = (index) => {
    setExpandedRow((prevIndex) => (prevIndex === index ? null : index));
  };

  const downloadQRCode = () => {
    const qrCodeURL = document
      .getElementById("qrCodeEl")
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = "QR_Code.png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  };

  const handleLogoUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setLogoImage(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const tenants = localStorage.getItem("tenant").toLowerCase();
  useEffect(() => {
    const fetchData = async () => {
      if (tenants === "globalminingequipments1" || tenants=='gme1') {
        try {
          const response = await axios.get(
            "https://go83cmp8wk.execute-api.ap-south-1.amazonaws.com/dev/gme-orders"
          );
          const formattedData = response.data.orders.map((order) => ({
            orderDate: order.orderDate,
            orderno: order.orderID,
            productName: order.orderDetails,
            requestBody: {
              rows: [{ qty: order.qty, nfc: "N/A", price: "N/A" }],
            },
            status: order.status,
          }));
          // Sort orders by orderDate in descending order
          const sortedData = formattedData.sort(
            (a, b) => new Date(b.orderDate) - new Date(a.orderDate)
          );
          setTenantData(sortedData);
          tenantDataRef.current = sortedData;
        } catch (error) {
          console.error("Error fetching GME orders:", error);
        }
      } else {
        try {
          const response = await axios.get(
            `https://0xb8bptkil.execute-api.ap-south-1.amazonaws.com/prod/employee?employeeid=${tenants}`
          );
          const data = response.data.orderdetials.reverse();
          setTenantData(data);
          tenantDataRef.current = data;
        } catch (error) {
          console.error("Error fetching tenant orders:", error);
        }
      }
    };

    fetchData();
  }, [tenants]);

  return (
    <div className="orders">
      <div className="orders-header">
        <div className="orders-text">
          <p>Orders</p>
        </div>
      </div>
      <div className="table-container">
        <table className="orders-table">
          <thead>
            <tr>
              <th>Order Timestamp</th>
              <th>Order ID</th>
              <th>Product</th>
              {tenants !== "globalminingequipments1" || tenants!=='gme1' && <th>Items</th>}
              <th>Status</th>
              {tenants !== "globalminingequipments1" || tenants!=='gme1' && <th>Amount</th>}
              <th>Quantity</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
  {tenantData &&
    Array.isArray(tenantData) &&
    tenantData.length > 0 &&
    tenantData.map((order, index) => {
      const orderDate = new Date(order.orderDate);
      const isValidDate = !isNaN(orderDate);

      return (
        <tr key={index}>
          <td>
            {isValidDate ? (
              <>
                <div>{format(orderDate, "MMMM dd yyyy")}</div>
                <div className="order-table-timestamp">
                  {format(orderDate, "HH:mm:ss")}
                </div>
              </>
            ) : (
              <div>Invalid Date</div>
            )}
          </td>
          <td>{order.orderno}</td>
          <td>{order.productName}</td>
          {tenants !== "globalminingequipments1" || tenants !== "gme1" && (
            <td>
              {order.requestBody.rows &&
                Array.isArray(order.requestBody.rows) &&
                order.requestBody.rows.map((item, itemIndex) => (
                  <div className="order-table-items" key={itemIndex}>
                    {item.nfc}
                  </div>
                ))}
            </td>
          )}
          <td>{getStatusStyle(order.status)}</td>
          {tenants !== "globalminingequipments1" || tenants !== "gme1" && (
            <td>
              {order.requestBody.rows &&
                Array.isArray(order.requestBody.rows) &&
                order.requestBody.rows.reduce((accumulator, item) => {
                  const price = parseFloat(item.price) || 0;
                  const qty = item.qty || 0;
                  return accumulator + price * qty;
                }, 0)}
            </td>
          )}
          <td>
            {order.requestBody.rows &&
              Array.isArray(order.requestBody.rows) &&
              order.requestBody.rows.reduce((accumulator, item) => {
                return accumulator + (item.qty || 0);
              }, 0)}
          </td>
          <td onClick={() => toggleRow(index)}>
            {tenants !== "globalminingequipments1" || tenants !== "gme1" && (
              <img
                src={DownArrow}
                alt="Expand"
                className={`order-expand-icon ${
                  expandedRow === index ? "rotated" : ""
                }`}
              />
            )}
          </td>
        </tr>
      );
    })}
</tbody>

        </table>
      </div>
    </div>
  );
}

export default Order;
