import React, { useState, useEffect } from "react";
import ReactApexChart from 'react-apexcharts';

const Sentiment = ({ reviewdata }) => {
  const [sentimentCount, setSentimentCount] = useState([0, 0, 0]); // Default counts: [positive, neutral, negative]

  useEffect(() => {
    if (!reviewdata || !reviewdata.reviews) {
      return; // Exit if reviewdata or reviews is not defined
    }

    // Initialize counts
    let positiveCount = 0;
    let neutralCount = 0;
    let negativeCount = 0;

    // Iterate through the reviews array to count sentiment occurrences
    reviewdata.reviews.forEach((review) => {
      try {
        // Parse the JSON string from the 'Sentiment' field
        const sentimentData = JSON.parse(review.Sentiment || "{}"); // Default to an empty object if Sentiment is undefined
        const sentiment = sentimentData.Sentiment?.sentiment; // Safely access the nested sentiment field

        if (sentiment === "positive") {
          positiveCount++;
        } else if (sentiment === "neutral") {
          neutralCount++;
        } else if (sentiment === "negative") {
          negativeCount++;
        }
      } catch (error) {
        console.error("Error parsing Sentiment field:", error);
      }
    });

    // Update state with the sentiment counts
    setSentimentCount([positiveCount, neutralCount, negativeCount]);
  }, [reviewdata]);

  const options6 = {
    series: sentimentCount,
    chart: {
      height: 390,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: 0,
        endAngle: 270,
        hollow: {
          margin: 5,
          size: '30%',
          background: 'transparent',
          image: undefined,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: false,
          },
        },
      },
    },
    colors: ['#00FF00', '#39539E', '#FF0000'],
    labels: ['Positive Review', 'Neutral Review', 'Negative Review'],
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          show: false
        }
      }
    }],
  };

  return <ReactApexChart options={options6} series={sentimentCount} type="radialBar" height={230} />;
};

export default Sentiment;
