import React, { useState, useRef, useEffect } from "react";
import "./index.css";
import ReloadIcon from "../assets/loading.png";
import DownArrowIcon from "../assets/dropdown.png";
import Cards from "./cards/Cards";
import Cardsgme from "./gme/cards/Cardsgme";
import image4 from "../assets/4.png";
import image5 from "../assets/5.png";
import * as XLSX from "xlsx";
import CINSelector from "./gme/CINSelector";
import image6 from "../assets/6.png";
import image7 from "../assets/7.png";
import image8 from "../assets/8.png";
import image9 from "../assets/9.png";
import Region from "./analyticaldata/region/Region";
import Morpen from "./gme/Morpen";
import Graph from "./analyticaldata/graph/Graph";
import Piechart from "./analyticaldata/piechart/Piechart";
import SalesDashboard from "../Sales";
import Marketing from "../Marketing";
import FileUploader from "./gme/FileUploader";
import CustomTable3 from "./gme/Customtable3";
import CustomTable from "./analyticaldata/tables";
import CustomTable2 from "./analyticaldata/tableCoustomisze";
import axios from "axios";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
//import type { RootState } from '../redux/store'
import { useSelector, useDispatch } from "react-redux";
import {
  decrement,
  increment,
  fetchNfcSessions,
  fetchProducts,
  fetchTenantData,
} from "../redux/counterSlice";
import CustomDatePicker from "./datepicker/CustomDatePicker";
import { useNavigate } from "react-router-dom";

function Dashboard() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Last 365 days");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [buttonText, setButtonText] = useState("");
  const nfcsessions = useSelector((state) => state.counter.nfcsessions);
  const selectedOptions = useSelector((state) => state.counter.selectedOptions);
  const product = useSelector((state) => state.counter.products);
  const tenentData = useSelector((state) => state.counter.tenentData);
  const tenantObj = useSelector((state) => state.counter.tenantObject);

  const dropdownRef = useRef(null);

  const tenant = localStorage.getItem("tenant").toLowerCase();

  var selectedtenant = tenentData.find(function (order) {
    return order.employeeid === tenant;
  });

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
    setShowDatePicker(false); // Close the date picker when toggling dropdown
  };

  const onCancelDatePicker = () => {
    setShowDatePicker(false); // Close the date picker when canceling
    setSelectedOption("Last 365 days"); // Set the selected option to "Today"
    setButtonText("Last 365 days"); // Update the button text to "Today"
  };

  useEffect(() => {
    // Calculate the total width of all tab names
    const tabList = document.querySelector(".react-tabs__tab-list");
    const totalTabWidth = Array.from(tabList.children).reduce(
      (acc, tab) => acc + tab.offsetWidth,
      0
    );

    // Set the width of the tab list container
    tabList.style.width = `${totalTabWidth + 70}px`;
    tabList.style.height = "36px";
  }, []);

  const count = useSelector((state) => state.counter.value);
  const [isRotating, setIsRotating] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const brandName = localStorage.getItem("BrandName");

    const data = JSON.parse(localStorage.getItem("user"));

    // Dispatch fetchNfcSessions action when the component mounts
    dispatch(fetchNfcSessions(tenant));
    dispatch(fetchProducts(tenant));
    dispatch(fetchTenantData(data.idToken.payload.name));
  }, [dispatch]);

  const [timeval, settimeval] = useState(Date.now());
  // useEffect(() => {
  //   const isMobile = () => {
  //     return window.innerWidth <= 767;
  //   };

  //   const showMessage = () => {
  //     if (isMobile()) {
  //       alert(
  //         "Congratulations on receiving FREE Credits! Use them to kickstart your journey. Please note: Our platform is currently not optimized for mobile devices. We recommend accessing it on your desktop or using the desktop view option in your mobile browser."
  //       );
  //       setTimeout(() => {
  //         navigate('/');
  //       }, 2000);
  //     }
  //   };

  //   showMessage();
  // }, []);

  // const filterNfcSessions = () => {
  //   if (!selectedOptions || selectedOptions === "All") {
  //     // If selectedOptions is undefined or 'ALL', return all nfcsessions
  //     return nfcsessions;
  //   } else {
  //     // Otherwise, filter nfcsessions based on selectedOptions
  //     return nfcsessions.filter((item) => item.title === selectedOptions);
  //   }
  // };

  const getDateComponents = (timestamp) => {
    const date = new Date(parseInt(timestamp));
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1, // Months are 0-based
      day: date.getDate(),
    };
  };

  const filterNfcSessions = () => {
    if (!selectedOptions || selectedOptions === "All") {
      const today = Date.now();
      // Get current date components
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1; // Months are 0-based
      const currentDay = currentDate.getDate();

      // Filter nfcsessions based on selectedOption
      switch (selectedOption) {
        case "Today":
          return nfcsessions.filter((item) => {
            const { year, month, day } = getDateComponents(item.timeline);
            return (
              year === currentYear &&
              month === currentMonth &&
              day === currentDay
            );
          });

        case "Last 15 days":
          return nfcsessions.filter((item) => {
            const { year, month, day } = getDateComponents(item.timeline);
            const itemDate = new Date(year, month - 1, day); // Month is 0-based in JavaScript
            const fifteenDaysAgo = new Date(
              currentDate.getTime() - 15 * 24 * 60 * 60 * 1000
            );
            return itemDate >= fifteenDaysAgo && itemDate <= currentDate;
          });

        case "Last 30 days":
          return nfcsessions.filter((item) => {
            const { year, month, day } = getDateComponents(item.timeline);
            const itemDate = new Date(year, month - 1, day); // Month is 0-based in JavaScript
            const thirtyDaysAgo = new Date(
              currentDate.getTime() - 30 * 24 * 60 * 60 * 1000
            );
            return itemDate >= thirtyDaysAgo && itemDate <= currentDate;
          });

        case "Last 90 days":
          return nfcsessions.filter((item) => {
            const { year, month, day } = getDateComponents(item.timeline);
            const itemDate = new Date(year, month - 1, day); // Month is 0-based in JavaScript
            const ninetyDaysAgo = new Date(
              currentDate.getTime() - 90 * 24 * 60 * 60 * 1000
            );
            return itemDate >= ninetyDaysAgo && itemDate <= currentDate;
          });
        case "Last 365 days":
          return nfcsessions.filter((item) => {
            const { year, month, day } = getDateComponents(item.timeline);
            const itemDate = new Date(year, month - 1, day); // Month is 0-based in JavaScript
            const threeSixtyFiveDaysAgo = new Date(
              currentDate.getTime() - 365 * 24 * 60 * 60 * 1000
            );
            return itemDate >= threeSixtyFiveDaysAgo && itemDate <= currentDate;
          });
        case "Custom Date Range":
          // Apply custom date range filtering here

          // Replace customStartDate and customEndDate with your custom date range values
          const customStartDate = new Date(startDate);
          const customEndDate = new Date(endDate);
          return nfcsessions.filter((item) => {
            const { year, month, day } = getDateComponents(item.timeline);
            const itemDate = new Date(year, month - 1, day); // Month is 0-based in JavaScript
            return itemDate >= customStartDate && itemDate <= customEndDate;
          });
        default:
          return nfcsessions;
      }
    } else {
      // Otherwise, filter nfcsessions based on selectedOptions and timeline
      let filteredData = nfcsessions.filter(
        (item) => item.title === selectedOptions
      );

      // Apply additional filtering based on time ranges
      const today = Date.now();
      const fifteenDaysAgo = today - 15 * 24 * 60 * 60 * 1000; // 15 days in milliseconds
      const thirtyDaysAgo = today - 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds
      const ninetyDaysAgo = today - 90 * 24 * 60 * 60 * 1000; // 90 days in milliseconds
      const threeSixtyFiveDaysAgo = today - 365 * 24 * 60 * 60 * 1000; // 365 days in milliseconds

      // Filter nfcsessions based on different time ranges
      if (selectedOption === "Today") {
        filteredData = filteredData.filter((item) => {
          const timelineTimestamp = parseInt(item.timeline);
          return timelineTimestamp >= today;
        });
      } else if (selectedOption === "Last 15 days") {
        filteredData = filteredData.filter((item) => {
          const timelineTimestamp = parseInt(item.timeline);
          return (
            timelineTimestamp >= fifteenDaysAgo && timelineTimestamp <= today
          );
        });
      } else if (selectedOption === "Last 30 days") {
        filteredData = filteredData.filter((item) => {
          const timelineTimestamp = parseInt(item.timeline);
          return (
            timelineTimestamp >= thirtyDaysAgo && timelineTimestamp <= today
          );
        });
      } else if (selectedOption === "Last 90 days") {
        filteredData = filteredData.filter((item) => {
          const timelineTimestamp = parseInt(item.timeline);
          return (
            timelineTimestamp >= ninetyDaysAgo && timelineTimestamp <= today
          );
        });
      } else if (selectedOption === "Last 365 days") {
        filteredData = filteredData.filter((item) => {
          const timelineTimestamp = parseInt(item.timeline);
          return (
            timelineTimestamp >= threeSixtyFiveDaysAgo &&
            timelineTimestamp <= today
          );
        });
      } else if (selectedOption === "Custom Date Range") {
        // Apply custom date range filtering here
        // Replace customStartDate and customEndDate with your custom date range values
        const customStartDate = new Date(startDate).getTime(); // Example start date
        const customEndDate = new Date(endDate).getTime(); // Example end date

        filteredData = filteredData.filter((item) => {
          const timelineTimestamp = parseInt(item.timeline);
          return (
            timelineTimestamp >= customStartDate &&
            timelineTimestamp <= customEndDate
          );
        });
      }

      return filteredData;
    }
  };

  const reloadData = () => {
    setIsRotating(true); // Start rotating
    // Simulate data reload, replace with actual data fetching logic
    setTimeout(() => {
      setIsRotating(false); // Stop rotating after a delay (adjust as needed)
    }, 2000); // Delay should match the duration of the rotation animation
  };

  var filteredNfcSessions = filterNfcSessions();

  const titleCounts = {};
  nfcsessions.forEach((item) => {
    if (item.tenant === tenant) {
      const title = item.title;
      titleCounts[title] = (titleCounts[title] || 0) + 1;
    }
  });

  // Extract unique titles
  const uniqueTitles = Object.keys(titleCounts);

  // Calculate the length of the unique titles array
  const uniqueTitlesCount = uniqueTitles.length;

  const [apiCalled, setApiCalled] = useState(true); // Add state for tracking API call

  const resultArray = product.map((item) => {
    const numOccurrences = nfcsessions.filter(
      (obj) => obj.title === item.title
    );
    const current = numOccurrences.map((item) => {
      const ipadress = item.geodata.ip;
      return {
        ipadress,
      };
    });
    const ipCounts = current.reduce((counts, { ipadress }) => {
      counts[ipadress] = (counts[ipadress] || 0) + 1;
      return counts;
    }, {});

    const uniqueIPAddresses = Object.keys(ipCounts);
    const totalOccurrences = uniqueIPAddresses.reduce(
      (sum, ipAddress) => sum + ipCounts[ipAddress],
      0
    );
    const totalUniqueIPAddresses = uniqueIPAddresses.length;

    const setavg =
      totalUniqueIPAddresses > 0
        ? totalOccurrences / totalUniqueIPAddresses
        : 0;

    return {
      title: item.title,
      numOccurrences: numOccurrences.length,
      avgScans: setavg,
    };
  });

  const apival = JSON.parse(localStorage.getItem("apival"));

  useEffect(() => {
    const lastAPICallDate = localStorage.getItem("lastAPICallDate");
    const currentDate = new Date();

    // Extract day, month, and year from the last API call date
    const lastDate = lastAPICallDate ? new Date(lastAPICallDate) : null;
    const lastDay = lastDate ? lastDate.getDate() : null;
    const lastMonth = lastDate ? lastDate.getMonth() : null;
    const lastYear = lastDate ? lastDate.getFullYear() : null;

    // Extract day, month, and year from the current date
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    // Check if a day has passed since the last API call
    if (
      !lastDay || // If there's no last API call date
      lastDay !== currentDay || // If day has changed
      lastMonth !== currentMonth || // If month has changed
      lastYear !== currentYear // If year has changed
    ) {
      // Update the stored date to today
      localStorage.setItem("lastAPICallDate", currentDate.toDateString());
      //const timestamp = 1713180155291;
      const date = new Date();
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns 0-based index
      const day = ("0" + date.getDate()).slice(-2);

      const formattedDate = `${year}-${month}-${day}`;

      const populatedResultArray = resultArray;

      if (apiCalled == true && populatedResultArray.length === product.length) {
        const apivalArray = Array.isArray(apival) ? apival : [apival];
        const postdata = {
          cals: [...populatedResultArray, ...apivalArray],
          id: tenant,
          time: formattedDate,
        };

        setApiCalled(false);
        try {
          const response = axios.put(
            "https://0xb8bptkil.execute-api.ap-south-1.amazonaws.com/prod/brand",
            JSON.stringify(postdata),
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          if (response.data) {
            // Handle successful API response
            // Set flag to prevent further API calls
          }
        } catch (error) {
          console.error(error);
        }
      }

      // Your existing code for making the API call goes here
      // Replace this comment with your existing code block
    }
  }, [resultArray, apival]);

  // useEffect(() => {
  //   //const timestamp = 1713180155291;
  //   const date = new Date();
  //   const year = date.getFullYear();
  //   const month = ("0" + (date.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns 0-based index
  //   const day = ("0" + date.getDate()).slice(-2);

  //   const formattedDate = `${year}-${month}-${day}`;
  //   // console.log("Date extracted:", formattedDate);

  //   const populatedResultArray = resultArray;

  //   // console.log(populatedResultArray)
  //   if (apiCalled == true && populatedResultArray.length === product.length) {
  //     const apivalArray = Array.isArray(apival) ? apival : [apival];
  //     const postdata = {
  //       cals: [...populatedResultArray, ...apivalArray],
  //       id: tenant,
  //       time: formattedDate,
  //     };
  //     console.log(postdata, resultArray);

  //     setApiCalled(false);
  //     try {
  //       const response = axios.put(
  //         "https://0xb8bptkil.execute-api.ap-south-1.amazonaws.com/prod/brand",
  //         JSON.stringify(postdata),
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );
  //       if (response.data) {
  //         // Handle successful API response
  //         console.log("API call successful");

  //         // Set flag to prevent further API calls
  //       }
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }
  // }, [resultArray, apival]);

  const [lastApiCallTime, setLastApiCallTime] = useState(new Date());
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    updateLastApiCallTime();

    const interval = setInterval(() => {
      setCurrentTime(new Date()); // Update current time every second
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  const updateLastApiCallTime = () => {
    // Simulated API call
    // Replace this with your actual API call logic
    // For demonstration purposes, let's set the last API call time to the current time
    const now = new Date();
    setLastApiCallTime(now);
    setCurrentTime(now);
  };
  const reloaddata = () => {
    dispatch(fetchNfcSessions(tenant));
    dispatch(fetchProducts(tenant));
    settimeval(Date.now());
    updateLastApiCallTime();
  };

  const timeDifference = Math.abs(
    currentTime.getTime() - lastApiCallTime.getTime()
  );
  const minutesDifference = Math.floor(timeDifference / (1000 * 60));

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRef.current &&
        event.target instanceof Node &&
        !dropdownRef.current.contains(event.target)
      ) {
        setDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsScrolled(scrollTop > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    // Set default selected option to "Today"
    setSelectedOption("Last 365 Days");
    setButtonText("Last 365 Days");

    dispatch(fetchNfcSessions(tenant));
    dispatch(fetchProducts(tenant));
  }, [dispatch, tenant]);

  // console.log(product);
  const [showArrow, setShowArrow] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const bodyHeight = document.body.offsetHeight;
      const scrollPosition =
        window.scrollY ||
        window.pageYOffset ||
        document.documentElement.scrollTop;

      if (bodyHeight - windowHeight - scrollPosition <= 0) {
        // User has scrolled to the bottom
        setShowArrow(false);
      } else {
        // User is not at the bottom
        setShowArrow(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  useEffect(() => {
    // Calculate the total width of all tab names
    const tabList = document.querySelector(".react-tabs__tab-list");
    const totalTabWidth = Array.from(tabList.children).reduce(
      (acc, tab) => acc + tab.offsetWidth,
      0
    );

    // Set the width of the tab list container
    tabList.style.width = `${totalTabWidth}px`;
    tabList.style.height = "36px";
  }, []);

  const options = [
    "Today",
    "Last 15 days",
    "Last 30 days",
    "Last 90 days",
    "Last 365 days",
    "Custom Date Range",
  ];
  const [showPopup, setShowPopup] = useState();
  const [activeTab, setActiveTab] = useState("details");
  useEffect(() => {
    const tenant = localStorage.getItem("tenant").toLowerCase();

    const tenantdata = JSON.parse(localStorage.getItem("tenantdata"));
    var selectedtenant = tenantdata.find(function (order) {
      return order.employeeid === tenant;
    });

    if (selectedtenant !== undefined) {
      setShowPopup(selectedtenant.firstLogin);
    }
  }, []);
  const handleOkClick = async () => {
    const tenant = localStorage.getItem("tenant").toLowerCase();

    const data = {
      id: tenant,
    };
    try {
      // Replace with your actual API endpoint and data
      await axios.patch(
        "https://4y53b9gp2k.execute-api.ap-south-1.amazonaws.com/prod/onboading",
        JSON.stringify(data),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setShowPopup(false);
    } catch (error) {
      console.error("Error making API call:", error);
    }
  };
  const handleSelectChange = (e) => {
    const value = e.target.value;
    setSelectedOption(value);
    setButtonText(value);
    if (value === "Custom Date Range") {
      setShowDatePicker(true);
    } else {
      setShowDatePicker(false);
    }
  };

  const onSelectDateRange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
    setShowDatePicker(false);

    if (start && end) {
      const startDateString = start.toLocaleDateString("en-GB"); // Format start date
      const endDateString = end.toLocaleDateString("en-GB"); // Format end date
      setButtonText(`${startDateString} to ${endDateString}`); // Set formatted date range as button text
    } else {
      setButtonText("Custom Date Range");
    }
    filterNfcSessions(); // Call your filter function here to update data based on the selected date range
    setSelectedOption("Custom Date Range");
  };
  const redirectToPricing = () => {
    //       // window.location.href = "/pricing";
  };
  return (
    <div className="dashboard">
      <span>
        {showPopup && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 9999999999,
            }}
          >
            <div
              style={{
                position: "relative", // Set position to relative
                backgroundColor: "#C0C0C0",
                color: "#00000",
                padding: "20px",
                textAlign: "center",
                fontWeight: "bold",
                height: "95%",
                width: "70%",
                boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
                borderRadius: "10px",
                overflowY: "scroll", // Enable scrolling
                scrollbarWidth: "thin", // Width of scrollbar
                scrollbarColor: "rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.1)", // Color of scrollbar
              }}
            >
              {/* Cross icon in circle */}
              <div
                style={{
                  position: "sticky", // Make the cross icon sticky
                  top: "10px",
                  left: "10px",
                  cursor: "pointer",
                  borderRadius: "50%",
                  backgroundColor: "#fff",
                  width: "30px",
                  height: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
                  zIndex: 999, // Ensure it's above the content
                }}
                onClick={handleOkClick}
              >
                <span style={{ fontSize: "20px" }}>×</span>
              </div>
              <p>Welcome!</p>
              <p>This is your step-by-step guide to start your journey!</p>

              {/* Animated arrow for scrolling */}
              <div
                className={`animated-arrow ${showArrow ? "visible" : "hidden"}`}
              >
                <div
                  className="bounce"
                  style={{
                    position: "fixed",
                    bottom: "20px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    animation: "bounce 1s infinite",
                    zIndex: 999,
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="48"
                    height="48"
                  >
                    <path
                      fill="currentColor"
                      d="M12 14.586L5.707 8.293a1 1 0 00-1.414 1.414l7.5 7.5a1 1 0 001.414 0l7.5-7.5a1 1 0 10-1.414-1.414L12 14.586z"
                    />
                  </svg>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "20px",
                  borderBottom: "1px solid black",
                }}
              >
                <button
                  onClick={() => setActiveTab("details")}
                  style={{
                    margin: "0 10px",
                    padding: "10px 20px",
                    backgroundColor: "transparent",
                    color: "black",
                    border: "none",
                    borderBottom:
                      activeTab === "details" ? "3px solid black" : "none",
                    cursor: "pointer",
                    fontWeight: activeTab === "details" ? "bold" : "normal",
                  }}
                >
                  Details
                </button>
                <button
                  onClick={() => setActiveTab("videos")}
                  style={{
                    margin: "0 10px",
                    padding: "10px 20px",
                    backgroundColor: "transparent",
                    color: "black",
                    border: "none",
                    borderBottom:
                      activeTab === "videos" ? "3px solid black" : "none",
                    cursor: "pointer",
                    fontWeight: activeTab === "videos" ? "bold" : "normal",
                  }}
                >
                  Demo Video
                </button>
              </div>
              <div style={{ marginBottom: "20px" }}>
                {activeTab === "details" && (
                  <div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {/* First Image Left, Text Right */}
                      <div style={{ display: "flex", marginBottom: "20px" }}>
                        <img
                          src={image4}
                          alt="Image 1"
                          style={{ flex: 1, width: "80%" }}
                        />
                      </div>
                      {/* Second Image Right, Text Left */}
                      <div
                        style={{
                          display: "flex",
                          marginBottom: "20px",
                          flexDirection: "row-reverse",
                        }}
                      >
                        <img
                          src={image5}
                          alt="Image 2"
                          style={{ flex: 1, width: "80%" }}
                        />
                      </div>
                      {/* Third Image Left, Text Right */}
                      <div style={{ display: "flex", marginBottom: "20px" }}>
                        <img
                          src={image6}
                          alt="Image 3"
                          style={{ flex: 1, width: "80%" }}
                        />
                      </div>
                      {/* Fourth Image Right, Text Left */}
                      <div
                        style={{
                          display: "flex",
                          marginBottom: "20px",
                          flexDirection: "row-reverse",
                        }}
                      >
                        <img
                          src={image7}
                          alt="Image 4"
                          style={{ flex: 1, width: "80%" }}
                        />
                      </div>
                      {/* Fifth Image Left, Text Right */}
                      <div style={{ display: "flex", marginBottom: "20px" }}>
                        <img
                          src={image8}
                          alt="Image 5"
                          style={{ flex: 1, width: "80%" }}
                        />
                      </div>
                      {/* Sixth Image Right, Text Left */}
                      <div
                        style={{
                          display: "flex",
                          marginBottom: "20px",
                          flexDirection: "row-reverse",
                        }}
                      >
                        <img
                          src={image9}
                          alt="Image 6"
                          style={{ flex: 1, width: "80%" }}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {activeTab === "videos" && (
                  <div>
                    <video controls style={{ width: "100%" }}>
                      <source
                        src="https://aiclearningvideos.s3.ap-south-1.amazonaws.com/Final+Video+For+AIC+1.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                )}
              </div>
              <button
                onClick={handleOkClick}
                className="firstpopupbtn"
                style={{
                  background:
                    "linear-gradient(92.49deg, #0956CC 0%, #1A4E9F 100%)",
                  boxShadow:
                    "4px 4px 16px 0px #FFFFFF33 inset, -4px -4px 16px 0px #00000040 inset",
                  color: "white",
                  fontSize: "12px",
                  fontWeight: "300",
                  border: "none",
                  borderRadius: "5px",
                  fontFamily: "Roboto",
                  margin: "10px",
                  padding: "5px",
                  cursor: "pointer",
                  overflow: "hidden",
                }}
              >
                OK, I Understand
              </button>
            </div>
          </div>
        )}

        <div className="dashboard-text">
          <p>Dashboard</p>
        </div>
        <div>
          {tenant == "globalminingequipments1" || tenant == "gme1" ? (
            <Tabs
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                minWidth: "150px",
              }}
            >
              <TabList>
                <Tab>Dashboard</Tab>
                <Tab>File Upload</Tab>
                <Tab>Codes Download</Tab>
              </TabList>

              <TabPanel>
                <div>
                  <Cardsgme
                    nfcsessions={filteredNfcSessions}
                    selectedtenant={selectedtenant}
                    selectedOptions={selectedOptions}
                  />
                </div>
                <div>
                  <Region nfcsessions={filteredNfcSessions} />
                </div>
                <div className="pie-graph-container">
                  <Graph nfcsession={filteredNfcSessions} product={product} />
                  <Piechart nfcsessions={filteredNfcSessions} />
                </div>
                <div>
                  {tenant == "globalminingequipments1" || tenant == "gme1" ? (
                    <CustomTable3 />
                  ) : (
                    <CustomTable
                      product={product}
                      nfcsessions={filteredNfcSessions}
                      selectedOptions={selectedOptions}
                      tenantdata={selectedtenant && selectedtenant.orderdetials} // Add conditional check
                    />
                  )}
                </div>

                {showDatePicker && (
                  <>
                    <CustomDatePicker
                      onSelectDateRange={onSelectDateRange}
                      onCancel={onCancelDatePicker} // Pass onCancelDatePicker function
                    />{" "}
                  </>
                )}
              </TabPanel>
              <TabPanel>
                <FileUploader />
              </TabPanel>
              <TabPanel o>
                <CINSelector />
              </TabPanel>
            </Tabs>
          ) : tenant == "minedrill1" ? (
            <Tabs
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                minWidth: "150px",
              }}
            >
              <TabList>
                <Tab>Codes Download</Tab>
              </TabList>

              <TabPanel>
                <CINSelector />
              </TabPanel>
            </Tabs>
          ) : tenant == "sol1" ? (
            <Tabs
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                minWidth: "150px",
              }}
            >
              <TabList>
              <Tab>Scans</Tab>
              <Tab>File Uploads</Tab>
              <Tab>Code Download</Tab>
              </TabList>
              <TabPanel>
                <div>
                  <Cards
                    nfcsessions={filteredNfcSessions}
                    selectedtenant={selectedtenant}
                    selectedOptions={selectedOptions}
                  />
                </div>
                <div>
                  <Region nfcsessions={filteredNfcSessions} />
                </div>
                <div className="pie-graph-container">
                  <Graph nfcsession={filteredNfcSessions} product={product} />
                  <Piechart nfcsessions={filteredNfcSessions} />
                </div>
                <div>
                  {tenant == "apmedcare1" ? (
                    <CustomTable2 />
                  ) : (
                    <CustomTable
                      product={product}
                      nfcsessions={filteredNfcSessions}
                      selectedOptions={selectedOptions}
                      tenantdata={selectedtenant && selectedtenant.orderdetials} // Add conditional check
                    />
                  )}
                </div>

                {showDatePicker && (
                  <>
                    <CustomDatePicker
                      onSelectDateRange={onSelectDateRange}
                      onCancel={onCancelDatePicker} // Pass onCancelDatePicker function
                    />{" "}
                  </>
                )}
              </TabPanel>
              <TabPanel>
                <FileUploader />
              </TabPanel>

              <TabPanel>
                <Morpen />
              </TabPanel>
            </Tabs>
          ) : (
            <Tabs style={{ marginTop: "20px", marginBottom: "20px" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <TabList>
                  <Tab>Scans</Tab>
                  <Tab>Integration</Tab>
                  <Tab>Social Media</Tab>
                </TabList>
                <div className="right-side-content">
                  <span className="last-updated" onClick={reloaddata}>
                    {minutesDifference === 0 && <span>Updated just now</span>}
                    {minutesDifference > 0 && minutesDifference <= 30 && (
                      <span>Last updated {minutesDifference} minutes ago</span>
                    )}
                    {minutesDifference > 30 && (
                      <span>Updated more than 30 minutes ago</span>
                    )}
                  </span>
                  <img
                    onClick={reloaddata}
                    style={{ height: "15px", width: "15px" }}
                    src={ReloadIcon}
                    alt="Reload"
                    className="reload-icon"
                  />
                  <div className="dailydropdown" ref={dropdownRef}>
                    <select
                      value={selectedOption}
                      onChange={handleSelectChange}
                      className="update-button"
                    >
                      {options.map((option) => (
                        <option
                          style={{ color: "black" }}
                          key={option}
                          value={option}
                        >
                          {option === "Custom Date Range" &&
                          startDate &&
                          endDate
                            ? `${startDate.toLocaleDateString(
                                "en-GB"
                              )} to ${endDate.toLocaleDateString("en-GB")}`
                            : option}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <TabPanel>
                <div>
                  <Cards
                    nfcsessions={filteredNfcSessions}
                    selectedtenant={selectedtenant}
                    selectedOptions={selectedOptions}
                  />
                </div>
                <div>
                  <Region nfcsessions={filteredNfcSessions} />
                </div>
                <div className="pie-graph-container">
                  <Graph nfcsession={filteredNfcSessions} product={product} />
                  <Piechart nfcsessions={filteredNfcSessions} />
                </div>
                <div>
                  {tenant == "apmedcare1" ? (
                    <CustomTable2 />
                  ) : (
                    <CustomTable
                      product={product}
                      nfcsessions={filteredNfcSessions}
                      selectedOptions={selectedOptions}
                      tenantdata={selectedtenant && selectedtenant.orderdetials} // Add conditional check
                    />
                  )}
                </div>

                {showDatePicker && (
                  <>
                    <CustomDatePicker
                      onSelectDateRange={onSelectDateRange}
                      onCancel={onCancelDatePicker} // Pass onCancelDatePicker function
                    />{" "}
                  </>
                )}
              </TabPanel>

              <TabPanel onClick={() => redirectToPricing()}>
                <SalesDashboard />
              </TabPanel>
              <TabPanel onClick={() => redirectToPricing()}>
                <Marketing />
              </TabPanel>
              <TabPanel o>
                <CINSelector />
              </TabPanel>
            </Tabs>
          )}
        </div>
      </span>
    </div>
  );
}

export default Dashboard;
