import "./ProductDetails.css";
import ReactDOM from "react-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Slider from "./Slider";
import QRCodeForm from "../genQRandBarcode/QRCodeForm";
import dots from "../../assets/DotsThreeOutlineVertical.png";
import reload from "../../assets/reload.png";
import pattern from "../../assets/pattern.png"
import download from "../../assets/DownloadBLUE.png";
import Map from "./am4chartMap/am4chartMap";
import Nft from "./Nft/Nft";
import ProductReview from "./Review/ProductReviews";
import Traceability from "./Tracebility/Traceability";
import InventoryProductDetails from "./Inventory/InventoryProductDetails";
import ProductMarketing from "./Marketing/ProductMarketing";
import Sales from "./Sales/Sales";
// import QRCode from "qrcode.react";
import { QRCode } from "react-qrcode-logo";
import Tooltip from "./toottip/Tooltip";
import deleteicon from "../../assets/bin.png";
import editicon from "../../assets/edit_1827951.png";
import loading from "../../assets/loader.gif";
import jsPDF from "jspdf";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
  fetchNfcSessions,
  fetchTenantData,
  setcredits,
} from "../../redux/counterSlice";
import Aztec from './AztecCode/Aztec';

function ProductDetails() {
  const { id, title } = useParams();
  const [activeDropdown, setActiveDropdown] = useState(null); // Tracks the active dropdown
  const tenantdata = useSelector((state) => state.counter.tenentData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const product = useSelector((state) => state.counter.products);
  const nfcsessions = useSelector((state) => state.counter.nfcsessions);
  const credits = useSelector((state) => state.counter.credits);
  const tenant = localStorage.getItem("tenant").toLowerCase();
  const [isPopupOpen, setPopupOpen] = useState(false);

  const openPopup = () => setPopupOpen(true);
  const closePopup = () => setPopupOpen(false);

  React.useEffect(() => {
    // Dispatch fetchNfcSessions action when the component mounts
    dispatch(fetchNfcSessions(tenant));
    //  dispatch(fetchProducts("genesis1"));// Replace "tenant" with your actual tenant value
  }, [dispatch]);
  const [activeProductImage, setActiveProductImage] = React.useState(0);
  const [propertyDetail, setpropertyDetail] = React.useState();
  const toggleDropdown = (productId) => {
    setActiveDropdown(activeDropdown === productId ? null : productId);
  };
  React.useEffect(() => {
    axios
      .get(
        `https://xc5ph8i3p0.execute-api.ap-south-1.amazonaws.com/prod/upload/?id=${id}`
      )
      .then((response) => {
        setpropertyDetail(response.data.Item);
        localStorage.setItem(
          "productname",
          JSON.stringify(response.data.Item.title)
        );
        localStorage.setItem(
          "productimage",
          JSON.stringify(response.data.Item.photo[0])
        );
      });
  }, [id]);

  const matchingObjects = nfcsessions.filter((item) => item.title === title);

  const latlongdata = React.useRef([]);

  // Define the headings you want in the CSV
  const headings = [
    "Tagid",
    "counter",
    "decimalValue",
    "openclose",
    "tenant",
    "timeline",
    "title",
  ];

  // Function to map each data object to match the specified headings
  const mapDataToHeadings = (object) => {
    return headings.map((heading) => {
      // If the object has a property matching the heading, return its value
      // Otherwise, return an empty string
      return object.hasOwnProperty(heading) ? object[heading] : "";
    });
  };

  const convertToCsvRow = (object) => {
    return Object.values(object)
      .map((value) => {
        // If the value contains a comma, enclose it in double quotes
        if (typeof value === "string" && value.includes(",")) {
          return `"${value}"`;
        }
        return value;
      })
      .join(",");
  };

  const csvContent = [
    headings.join(","), // Join headings with comma
    ...matchingObjects.map((object) => {
      const mappedData = mapDataToHeadings(object);
      return convertToCsvRow(mappedData);
    }), // Convert each data object to a CSV row
  ].join("\n");

  // Function to download CSV file
  const downloadCsv = () => {
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "export.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  latlongdata.current = matchingObjects.map((item) => {
    const [latitude, longitude] = item.geodata.loc.split(",").map(Number);
    return {
      latitude,
      longitude,
      size: 4,
      tooltip: item.geodata.city /* specify your tooltip logic here */,
      fill: "#F00" /* specify your fill logic here */,
    };
  });

  function getLatestTimestampData(data) {
    const latestDataObject = {};

    data.forEach((item) => {
      const tagId = item.Tagid;

      if (
        !latestDataObject[tagId] ||
        new Date(item.timeline) > new Date(latestDataObject[tagId].timeline)
      ) {
        latestDataObject[tagId] = item;
      }
    });
    const latestDataArray = Object.values(latestDataObject);

    return latestDataArray;
  }
  const latestTimestampData = getLatestTimestampData(matchingObjects);

  function sysmathsdDate(dates) {
    const time = Number(dates);
    const date = new Date(time);

    // Extract date components
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Months are zero-indexed
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    // Format the date
    return `${year}-${month < 10 ? "0" : ""}${month}-${
      day < 10 ? "0" : ""
    }${day} ${hours}:${minutes}:${seconds}`;
  }

  const [testtime, settesttime] = useState(Date.now());

  const qrcodeDownload = async (propertyDetail) => {
    // Check if propertyDetail exists and has a link property
    if (propertyDetail && propertyDetail.link) {
      // If true, proceed to handleOpen
      handleOpen();
    } else {
      // If false, prompt the user with a confirmation dialog
      const userConfirmed = window.confirm(
        "By clicking OK, your wallet will be deducted by 10 credits."
      );
      
      // If the user confirms, proceed to handleOpen
      if (userConfirmed) {
       await handleOpen();
        try {
          // Capture the QR code as a base64 encoded string
          const qrCodeURL = document
            .getElementById("qrCodeEl")
            .toDataURL("image/png");
      
          // Prepare the payload for the POST request
          const payload = {
            qrCode: qrCodeURL
          };
      
          // Send the encoded string to the API using a POST request
          const response = await fetch("https://4y53b9gp2k.execute-api.ap-south-1.amazonaws.com/prod/uploadproducturl", {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(payload)
          });
      
          if (response.ok) {
            const result = await response.json();
            const datas = JSON.parse(localStorage.getItem('user') || '{}');
            const usename= localStorage.getItem('fname');
            console.log("QR Code uploaded successfully:", JSON.parse(result));
            const parts = usename.split('-');
            const data=JSON.parse(result)
            const emailData = {
              recipient_email:  datas.idToken.payload.email,
              brand_name: datas.idToken.payload.name,
              first_name:    parts[0],
              template_type: 'qr_code_download', // or 'password_change', 'ticket_generation', 'qr_code_download'
              // "ticket_id": "12345",
              // "issue_description": "Test issue description",
              date_raised: formatDate(),
              date_generated:  formatDate(),
              description: propertyDetail.title,
              attachment_url:data.imageUrl
            };
      
            try {
              const response = await axios.post(
                'https://4y53b9gp2k.execute-api.ap-south-1.amazonaws.com/prod/emails',
                emailData,
                {
                  headers: {
                    'Content-Type': 'application/json',
                  },
                }
              );
      
              console.log('Email sent successfully!', response.data);
            } catch (error) {
              console.error('Error sending email:', error.response ? error.response.data : error.message);
            }
          } else {
            console.error("Failed to upload QR Code:", response.statusText);
          }
        } catch (error) {
          console.error("Error uploading QR Code:", error);
        }
      }
    }
  };
  
  function formatDate() {
    const date = new Date();
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();

    // Suffix for the day
    const suffix = (day) => {
        if (day > 3 && day < 21) return 'th'; // 4-20
        switch (day % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
        }
    };

    return `${day}${suffix(day)} ${month} ${year}`;
}
  const handleOpen = async () => {
    if (
      propertyDetail &&
      propertyDetail.link &&
      propertyDetail.link.length > 0
    ) {
      setOpen(true);
    } else if (credits >= 10) {
      const rows = {
        id: 1,
        nfc: "QR code",
        price: 10,
        qty: 1,
      };
      const postdata = {
        productid: propertyDetail.productId,
        code: `${testtime}`,
        rows: [rows],
        productName: JSON.parse(localStorage.getItem("productname")),
        productimage: JSON.parse(localStorage.getItem("productimage")),
        tenant: localStorage.getItem("tenant").toLowerCase(),
        link: `https://media.arvo.services/?uid=${testtime}x248`,
      };

      axios
        .put(
          "https://xc5ph8i3p0.execute-api.ap-south-1.amazonaws.com/prod/upload",
          postdata
        )
        .then((response) => {
          creditupdate();
          setOpen(true);
        })
        .catch((error) => {
          // Handle the error
          console.error(error);
        });
      setOpen(true);
    } else {
      toast.warn(
        `You are not eligible to create QR codes, under ${credits} credits  Upgrade Plan.`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    }
  };

  const qrval = `https://media.arvo.services/?uid=${testtime}x248`;

  // const downloadQRCode = () => {
  //   const qrCodeURL = document
  //     .getElementById("qrCodeEl")
  //     .toDataURL("image/png")
  //     .replace("image/png", "image/octet-stream");
  //   let aEl = document.createElement("a");
  //   aEl.href = qrCodeURL;
  //   aEl.download = "QR_Code.png";
  //   document.body.appendChild(aEl);
  //   aEl.click();
  //   document.body.removeChild(aEl);
  // };
  const downloadQRCode = () => {
    // Create a temporary canvas for rendering the QR code without the pattern
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const qrCodeData = convertLink(propertyDetail.link[0]); // The data for your QR code

    // Use the same library you used for the QRCode component, but render it without the logo
    const qrCodeWithoutLogo = (
      <QRCode
        value={qrCodeData}
        size={280}
        qrStyle="square"
        eyeRadius={5}
        ecLevel="H"
      />
    );

    // Temporarily render the QR code into a container and capture its content
    const qrCodeContainer = document.createElement("div");
    document.body.appendChild(qrCodeContainer);
    ReactDOM.render(qrCodeWithoutLogo, qrCodeContainer);

    // Capture QR code image as base64
    const qrCanvas = qrCodeContainer.querySelector("canvas");
    const qrCodeURL = qrCanvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");

    // Trigger download
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = "QR_Code.png";
    aEl.click();

    // Clean up temporary elements
    ReactDOM.unmountComponentAtNode(qrCodeContainer);
    document.body.removeChild(qrCodeContainer);
  };

  const handleLabelPrintingClick = async(propertyDetail) => {

    if (propertyDetail && propertyDetail.link) {
      // If true, proceed to handleOpen
      localStorage.setItem('link',propertyDetail.link )
      navigate(`/lable-printing/${title}/${id}`);
    } else {
      // If false, prompt the user with a confirmation dialog
      const userConfirmed = window.confirm(
        "first Download qr code."
      );
    }
   
  };

  const [qrcodeval, setqrcodeval] = React.useState(2);
  const qrCodeRefs = React.useRef([]);
  const [isReady, setIsReady] = React.useState(false);

  // const downloadCSV = () => {
  //   const numberOfPages = Math.round(qrcodeval / 3);
  //   const qrCodesPerPage = 3;

  //   // Create a new jsPDF instance
  //   const pdf = new jsPDF();

  //   // Add content to the PDF

  //   for (let pageIndex = 0; pageIndex < numberOfPages; pageIndex++) {
  //     if (pageIndex > 0) {
  //       pdf.addPage();
  //     }

  //     for (let qrCodeIndex = 1; qrCodeIndex < qrCodesPerPage; qrCodeIndex++) {
  //       //   const qrCodeCanvas = qrCodeRefs.current[pageIndex][qrCodeIndex];
  //       const qrCodeCanvas = document.getElementById("qrCodeEl");

  //       console.warn("hhh", qrCodeCanvas);
  //       const qrCodeDataURL = qrCodeCanvas.toDataURL("image/png");

  //       Array.from({ length: numberOfPages }, (_, pageIndex) => {
  //         Array.from({ length: qrCodesPerPage }, (_, qrCodeIndex) => {
  //           // Adjust the size and position of the QR code
  //           pdf.addImage(
  //             qrCodeDataURL,
  //             "PNG",
  //             10, // X position
  //             qrCodeIndex * 60 + 10, // Y position
  //             40, // Width
  //             40 // Height
  //           );

  //           // Adjust the position of the text
  //           pdf.text(
  //             `QR Code of ${propertyDetail.title} ${
  //               pageIndex + qrCodeIndex - 1
  //             }`,
  //             60, // X position
  //             qrCodeIndex * 60 + 30 // Y position
  //           );
  //         });
  //       });
  //     }
  //   }

  //   // Save or display the PDF
  //   pdf.save(`${propertyDetail.title}-${Date.now()}.pdf`);
  // };

  

  const handelAilables = () => {
    const type=localStorage.getItem("subtype");
    if(type=='type2')
    {
      navigate('/productcart-ai-lables')

    }
    else{
    toast.warn(
      "You are not eligible to create AI Lables under the free tier plan.",
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      }
    );
    }
  };
  const downloadCSV = () => {
    toast.warn(
      "You are not eligible to create serialized QR codes under the free tier plan.",
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      }
    );

    // const numberOfPages = Math.ceil(qrcodeval / 9); // Calculate the number of pages needed
    // const qrCodesPerPage = 9; // Three lines of three QR codes each

    // // Create a new jsPDF instance
    // const pdf = new jsPDF();

    // // Loop through each page
    // for (let pageIndex = 0; pageIndex < numberOfPages; pageIndex++) {
    //   if (pageIndex > 0) {
    //     pdf.addPage();
    //   }

    //   pdf.text(
    //     `QR Code for ${propertyDetail.title}`,
    //     50, // X position
    //     20 // Y position
    //   );

    //   // Loop through each line on the page
    //   for (let lineIndex = 0; lineIndex < 3; lineIndex++) {
    //     // Loop through each QR code on the line
    //     for (let qrCodeIndex = 0; qrCodeIndex < 3; qrCodeIndex++) {
    //       const qrCodeNumber =
    //         pageIndex * qrCodesPerPage + lineIndex * 3 + qrCodeIndex + 1; // Calculate the QR code number

    //       if (qrCodeNumber <= qrcodeval) {
    //         // Only add QR code if it exists
    //         const qrCodeCanvas = document.getElementById(`qrCodeEl`);
    //         const qrCodeDataURL = qrCodeCanvas.toDataURL("image/png");
    //         const postdata = {
    //           productid: propertyDetail.productId,
    //           code: `${Date.now()}${qrCodeNumber}`,
    //         };

    //         axios
    //           .put(
    //             "https://xc5ph8i3p0.execute-api.ap-south-1.amazonaws.com/prod/upload",
    //             postdata
    //           )
    //           .then((response) => {
    //             console.log("API Response:", response, response.data);

    //             setOpen(true);
    //           })
    //           .catch((error) => {
    //             // Handle the error
    //             console.error(error);
    //           });

    //         // Adjust the size and position of the QR code
    //         const xPosition = qrCodeIndex * 60 + 10; // Adjust X position
    //         const yPosition = 30 + lineIndex * 80; // Adjust Y position

    //         pdf.addImage(
    //           qrCodeDataURL,
    //           "PNG",
    //           xPosition,
    //           yPosition,
    //           50, // Width
    //           50 // Height
    //         );

    //         // Add QR code numbering below each QR code
    //         pdf.text(
    //           `QR Code ${qrCodeNumber}`,
    //           xPosition + 10, // Adjust X position
    //           yPosition + 60 // Adjust Y position
    //         );
    //       }
    //     }
    //   }
    // }

    // // Save or display the PDF
    // pdf.save(`${propertyDetail.title}-${Date.now()}.pdf`);
  };

  const creditupdate = async () => {
    dispatch(setcredits(credits - 10));
    const newCreditHistoryData = {
      date: Date.now(),
      creditused: 10,
      qrcodedownloaded: 1,
      productName: title,
      productid: id,
      credit: "used",
    };
    const postdata = {
      employeeid: tenant,
      credits: credits - 10,
      newCreditHistoryData,
    };

    try {
      const response = axios.put(
        "https://x5i01p9a23.execute-api.ap-south-1.amazonaws.com/prod/credits",
        JSON.stringify(postdata),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data) {
        console.log("API call successful");

        // Set flag to prevent further API calls
      }
    } catch (error) {
      console.error(error);
    }
  };

  const setnumberofQR = (e) => {
    setqrcodeval(e.target.value);
  };
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    window.location.reload();
  };

  const closeDropdownOptionClick = () => {
    setActiveDropdown(null); // Close the dropdown when an option is clicked
  };
  const closeDropdownOptionClickedit = (product) => {
    navigate(`/editproduct/${product.title}/${product.productId}`);
  };
  const closeDropdownOptionClickdelete = (product) => {
    const response = window.confirm(
      "Are you sure you want to delete this property?"
    );

    if (response) {
      try {
        const apiUrl = `https://xc5ph8i3p0.execute-api.ap-south-1.amazonaws.com/prod/upload/?id=${product.productId}`;

        const deleteResponse = fetch(apiUrl, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            // You may include additional headers if needed, like authentication headers.
          },
        });

        if (deleteResponse) {
          // Property deleted successfully

          toast.error(`${propertyDetail.title} item deleted successfully!`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            //transition: Bounce,
          });

          setTimeout(() => {
            navigate("/product");
            window.location.reload();
          }, 5000);

          // You can perform any additional actions after successful deletion
          // e.g., navigate to another page or update the UI.
        } else {
          // Handle API error for delete request
          console.error("Error deleting property:", deleteResponse);
          navigate("/product");
        }
      } catch (error) {
        console.error("Error during delete request:", error);
        navigate("/dashboard");
      }
    }
  };

  const [logoImage, setLogoImage] = useState(pattern);
  const [logoOpacity, setLogoOpacity] = useState(1);
  const [qrStyle, setQRStyle] = useState("dots");
  const [logoSize, setLogoSize] = useState(100);

  const handleLogoUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setLogoImage(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handelrequestTags = () => {
    const type=localStorage.getItem("subtype");
    if(type=='type2')
    {
      navigate('/productcart')

    }
    else{
    toast.warn(
      "You are not eligible to request tags, under the free tier plan.",
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      }
    );
    }
  };
  function convertLink(inputLink) {
    // Check if the link contains 'arvo.media' and replace it with 'media.arvo.services'
    const updatedLink = inputLink.replace("arvo.media", "media.arvo.services");
    
    // Return the updated link
     
    // const urlObj = new URL(updatedLink);
    return updatedLink;
  }
  
  function extractUid(url) {
    const urlObj = new URL(url); // Create a URL object
    return urlObj.searchParams.get("uid"); // Get the 'uid' parameter
}
  useEffect(() => {
    const tabList = document.querySelector(".react-tabs__tab-list");
    const totalTabWidth = Array.from(tabList.children).reduce(
      (acc, tab) => acc + tab.offsetWidth,
      0
    );
    tabList.style.width = `${totalTabWidth}px`;
    tabList.style.height = "36px";
  }, []);
  const skuid = propertyDetail ? propertyDetail.productId : "";

  return (
    <div className="productdetails">
    {open === false ? <ToastContainer /> : null}
  
    {open && (
        <div className="popup">
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          {propertyDetail && propertyDetail.link ? (
            <div className="popup-content">
              <div className="close-button" onClick={handleClose}>
                <span>×</span>
              </div>
              <h2 className="popup-title">{propertyDetail.title}</h2>
              <p className="popup-description">{propertyDetail.description}</p>
              <div className="popup-flex">
                <div className="customize-section">
                  <h3>Copy-proof Code</h3>
                  <div className="customize-item">
                    <button onClick={downloadQRCode}>Download Code</button>
                    <p>*Downloaded QR Code will not be copy proof*</p>
                  </div>
                </div>
                <div className="qr-code-section">
                <p>This is a sample image</p>
                  <QRCode
                    id="qrCodeEl"
                    value={convertLink(propertyDetail.link[0])}
                    size={280}
                    logoImage={pattern}
                    logoHeight={logoSize}
                    logoWidth={logoSize}
                    logoOpacity={1}
                    enableCORS={true}
                    qrStyle="square"
                    eyeRadius={5}
                    ecLevel="H"
                  />
        
                </div>
              </div>
            </div>
          ) : (
            <div className="popup-content">
              <div className="close-button" onClick={handleClose}>
                <span>×</span>
              </div>
              <h2 className="popup-title">{propertyDetail.title}</h2>
              <p className="popup-description">{propertyDetail.description}</p>
              <div className="popup-flex">
                <div className="customize-section">
                  <h3> Copy-proof Code</h3>
                  <div className="customize-item">
                    <button onClick={downloadQRCode}>Download Code</button>
                  </div>
                </div>
                <div className="qr-code-section">
                  <p>This is a dummy Copy Proof QR</p>
                  <QRCode
                    id="qrCodeEl"
                    value={qrval}
                    size={280}
                    logoImage={pattern}
                    logoHeight={logoSize}
                    logoWidth={logoSize}
                    logoOpacity={1}
                    enableCORS={true}
                    qrStyle="square"
                    eyeRadius={5}
                    ecLevel="H"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
  
    <Tabs style={{ marginTop: "20px" }}>
      <TabList>
        <Tab>Product Detail</Tab>
        <Tab>Review</Tab>
        <Tab>Tag Inventory</Tab>
        <Tab>Traceability</Tab>
        <Tab>NFT</Tab>
        <Tab>Integration</Tab>
        <Tab>Arvo Codes</Tab>
      </TabList>
      <TabPanel>
        <div className="productdetails-header">
          <div className="productdetails-text">
            <p>Product Detail</p>
          </div>
        </div>
        <div className="product-details-content">
          <div className="product-details-content-text">
            <div>
              <p className="product-details-product-name" style={{ color: "#0956CC" }}>
                {propertyDetail ? propertyDetail.title : "Product Name"}
              </p>
            </div>
            <div className="product-details-container-info">
              <p className="product-details-product-info">
                <span style={{ color: "#0956CC" }}> Product Detail: </span>
                {propertyDetail ? propertyDetail.description : " Product Details"}
              </p>
            </div>
            <div
              style={{
                borderBottom: "1px solid black",
                margin: "10px 0",
                width: "100%",
              }}
            ></div>
            <div className="product-details-container-info">
              <p className="product-details-product-info">
                <span style={{ color: "#0956CC" }}> Product Sku Id: </span>
                {propertyDetail ? propertyDetail.productId : " Product Sku ID"}
              </p>
            </div>
            <div
              style={{
                borderBottom: "1px solid black",
                margin: "10px 0",
                width: "100%",
              }}
            ></div>
            <div className="product-details-container-info">
              <p className="product-details-product-info">
                <span style={{ color: "#0956CC" }}> Product Category: </span>
                {propertyDetail ? propertyDetail.category : " Product Category"}
              </p>
            </div>
            <div className="product-details-button-container">
              <Tooltip text="NFC-enabled tags that are tamper-proof and customizable to integrate seamlessly with your product. Click here to request now.">
                <button
                  className="product-details-buttons-end"
                  onClick={handelrequestTags}
                >
                  Request NFC Tags
                </button>
              </Tooltip>
              <Tooltip text="Tamper-proof labels for your products with a unique digital signature detectable only through our algorithm. Click here to request now.">
                <button
                  className="product-details-buttons-end"
                  onClick={handelAilables}
                >
                  Request AI Label Tags
                </button>
              </Tooltip>
              <Tooltip
                text={
                  propertyDetail && propertyDetail.link
                    ? "Generate serialized or non-serialized Copy-proof codes for your products. A quick and easy solution to engage with your customers. Click here to request now."
                    : "Generate serialized or non-serialized Copy-proof codes for your products. A quick and easy solution to engage with your customers. Click here to request now."
                }
              >
                <button
                  className="product-details-buttons-end"
                  onClick={() => qrcodeDownload(propertyDetail)}
                >
                  {propertyDetail && propertyDetail.link
                    ? "Re-download Copy-proof Code"
                    : "Request Copy-proof Codes"}
                </button>
              </Tooltip>
              <button className="product-details-buttons-end" onClick={()=>handleLabelPrintingClick(propertyDetail)}>
              Serialized QR on Label
            </button>
            </div>
            <div className="product-details-button-container">
            <button className="aztec-datamatrix-btn" style={{fontSize:"10px", fontFamily:"Roboto", marginLeft:"10px"}} onClick={openPopup}>
        Product and Part Code
      </button>

      <Aztec isOpen={isPopupOpen} onClose={closePopup} skuid={skuid} />
            </div>
          </div>
          <div className="product-details-right">
            <div className="edit-delete-button">
              <button
                onClick={() => closeDropdownOptionClickedit(propertyDetail)}
                className="edit-delte"
              >
                Edit
              </button>
              <button
                onClick={() => closeDropdownOptionClickdelete(propertyDetail)}
                className="edit-delte-btn"
              >
                Delete
              </button>
            </div>
            <div className="product-details-content-image" style={{ paddingLeft: "65px", marginTop:"20px" }}>
                {propertyDetail ? (
                  <Slider images={propertyDetail.photo} />
                ) : (
                  <img
                    src={loading}
                    alt={`product-image`}
                    className="grid-item small-image css"
                  />
                )}
            </div>
          </div>
        </div>
        <div className="product-details-analytical-info">
          <div className="above-table-texts">
            <div>
              <span className="product-details-recent-scans">Recent Scans</span>
            </div>
            <div className="product-details-text-right">
              <button className="print-button" onClick={downloadCsv}>
                <span>Download</span>
                <img src={download} alt="Print" className="button-print-img" />
              </button>
            </div>
          </div>
          <div className="table-and-map">
            <div className="table-container">
              <table className="product-details-table">
                <thead>
                  <tr>
                    <th>Tag ID</th>
                    <th>City</th>
                    <th>Country</th>
                    <th>Status</th>
                    <th>Time-Stamp</th>
                  </tr>
                </thead>
                <tbody>
                  {latestTimestampData.map((item) => (
                    <tr key={item.Tagid}>
                      <td>{item.Tagid.length > 10 ? item.Tagid.slice(0, 10) + "..." : item.Tagid}</td>
                      <td>{item.geodata.city}</td>
                      <td>{item.geodata.country}</td>
                      <td>{item.openclose === "not a TT tag" ? "QR Code" : "TT tag"}</td>
                      <td>{sysmathsdDate(item.timeline)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="product-details-map-container" style={{ margin: "10px" }}>
              <Map data={latlongdata.current} />
            </div>
          </div>
        </div>
      </TabPanel>
      <TabPanel>
        <ProductReview id={title} />
      </TabPanel>
      <TabPanel>
        <InventoryProductDetails id={id} />
      </TabPanel>
      <TabPanel>
        <Traceability skuid={propertyDetail ? propertyDetail.productId : ""} />
      </TabPanel>
      <TabPanel>
        <Nft />
      </TabPanel>
      <TabPanel>
        <Sales />
      </TabPanel>
      <TabPanel>
        <QRCodeForm />
      </TabPanel>
    </Tabs>
  </div>
  );
}

export default ProductDetails;

