import React, { useEffect, useState } from "react";
import axios from "axios";
import "./CounterfeitReportPage.css";

function CounterfeitReportPage() {
  const [counterfeitData, setCounterfeitData] = useState([]);

  useEffect(() => {
    const fetchCounterfeitData = async () => {
      try {
        const response = await axios.get(
          "https://go83cmp8wk.execute-api.ap-south-1.amazonaws.com/dev/gmelambdascan"
        );
        if (response.status === 200) {
          // Filter data where numberofscan > 9
          const filteredData = response.data.filter(
            (item) => item.numberofscan > 9
          );
          setCounterfeitData(filteredData);
        }
      } catch (error) {
        console.error("Error fetching counterfeit data:", error);
      }
    };

    fetchCounterfeitData();
  }, []);

  return (
    <div className="counterfeit-report-container" style={{ marginTop: "90px" }}>
      <h1>Counterfeit Reports</h1>
      {counterfeitData.length > 0 ? (
        <table className="counterfeit-report-table">
          <thead>
            <tr>
              <th rowSpan="2">UID</th>
              <th rowSpan="2">Number of Scans</th>
              <th rowSpan="2">Last Scan Date</th>
              <th colSpan="2">Last Scan Location</th>
              <th colSpan="3">Product Details</th>
            </tr>
            <tr>
              <th>City</th>
              <th>Region</th>
              <th>Product Name</th>
              <th>Part</th>
              <th>Challan Number</th>
            </tr>
          </thead>
          <tbody>
            {counterfeitData.map((item, index) => (
              <tr key={index}>
                <td>{item.uid}</td>
                <td>{item.numberofscan}</td>
                <td>{new Date(item.time).toLocaleDateString()}</td>
                <td>{item.geodata.city}</td>
                <td>{item.geodata.region}</td>
                <td>G250</td>
                <td>{item.detail?.part || "N/A"}</td>
                <td>{item.detail?.chalanNumber || "N/A"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No counterfeit reports with scans greater than 9.</p>
      )}
    </div>
  );
}

export default CounterfeitReportPage;
