import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "./BrandTickets.css";
import TicketDashboard from "./TicketDashboard";
import styles from "./Dashboard.module.css";
import Usertable from "./UserTable/UserTable";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Chart from "react-apexcharts";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

function BrandTickets() {
  const tenants = localStorage.getItem("tenant").toLowerCase();

  const [ticketsdata, setTicketsdata] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://bxcswtw14g.execute-api.ap-south-1.amazonaws.com/prod/?id=${tenants}`
        );
        console.log(response.data.Item, response);
        setTicketsdata(response.data.Item);
        // Handle response data here
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Call the async function immediately
  }, []);

  console.log(ticketsdata);

  const redirectToPricing = () => {
    if (type == "type2") {
    } else {
      // window.location.href = "/pricing";
    }
  };
  const type = localStorage.getItem("subtype");
  return (
    <div
      className="superadmin-usermanager"
      style={{ paddingBottom: "20px", paddingTop: "20px" }}
    >
      <div>
        <span
          className="superadmin-dashboard-header"
          styles={{ width: "100%" }}
        >
          Tickets
        </span>
      </div>
      <Tabs style={{ marginTop: "20px" }}>
        <TabList style={{ marginBottom: "30px", width: "30%" }}>
          <Tab>Dashboard</Tab>
          <Tab>Tickets List</Tab>
        </TabList>
        <TabPanel
          className={`${type === "type2" ? "" : "blur-free-tier-content"}`}
          onClick={() => redirectToPricing()}
        >
          <TicketDashboard ticketsdata={ticketsdata} />
        </TabPanel>
        <TabPanel
          className={`${type === "type2" ? "" : "blur-free-tier-content"}`}
          onClick={() => redirectToPricing()}
        >
          {" "}
          <Usertable ticketsdata={ticketsdata} />
        </TabPanel>
        <TabPanel>
          <p>Coming soon</p>
        </TabPanel>
        <TabPanel>
          <p>Coming soon</p>
        </TabPanel>
      </Tabs>
    </div>
  );
}

export default BrandTickets;
