// src/components/Admin/data/Morpen.jsx

import React, { useState, useEffect } from "react";
import axios from "axios";
import { saveAs } from "file-saver";
import { format } from "date-fns"; // To format date
import "./CINSelector.css";
import bmpImage from "../../assets/morpen/cdp_image.bmp"; // BMP image to embed
import pngImage from "../../assets/morpen/QR_Code.png"; // Existing PNG image
import JSZip from "jszip";
import QRCode from "qrcode"; // QR code generation

const Morpen = () => {
  // ---------------- Batch Details States ----------------
  const [selectedBatch, setSelectedBatch] = useState("");
  const [batchDate, setBatchDate] = useState("");
  const [batchQuantity, setBatchQuantity] = useState("");
  const [downloadMessage, setDownloadMessage] = useState("");

  // We'll offer 20 batch number choices.
  const batchNumbers = Array.from({ length: 20 }, (_, i) =>
    `B${(i + 1).toString().padStart(2, "0")}`
  );

  // ---------------- CIN Selector States ----------------
  const [cinList, setCinList] = useState([]);
  const [uniqueRefNos, setUniqueRefNos] = useState([]);
  const [filteredCinList, setFilteredCinList] = useState([]);
  const [selectedRefNo, setSelectedRefNo] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [refPartNo, setRefPartNo] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [quantity, setQuantity] = useState("");
  const [fileType, setFileType] = useState("Notepad");
  const [isDownloadEnabled, setIsDownloadEnabled] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isBatchPrinting, setIsBatchPrinting] = useState(false);
  const [batchPrintQty, setBatchPrintQty] = useState("");

  // ---------------- Fetch the CIN list ----------------
  useEffect(() => {
    const fetchCinList = async () => {
      try {
        const response = await axios.get(
          "https://go83cmp8wk.execute-api.ap-south-1.amazonaws.com/dev/records"
        );
        setCinList(response.data);
        const uniqueRefs = [
          ...new Set(response.data.map((cin) => cin.RefNo)),
        ];
        setUniqueRefNos(uniqueRefs);
        setFilteredCinList(uniqueRefs);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching CIN list:", error);
      }
    };
    fetchCinList();
  }, []);

  // ---------------- Event Handlers for CIN Selector ----------------
  const handleFilterChange = (event) => {
    const filterText = event.target.value;
    setSelectedRefNo(filterText);
    setShowDropdown(true);
    const filtered = uniqueRefNos.filter((refNo) =>
      refNo ? refNo.toLowerCase().includes(filterText.toLowerCase()) : false
    );
    setFilteredCinList(filtered);
  };

  const handleRefNoSelect = (refNo) => {
    setSelectedRefNo(refNo.replace(/\//g, "-"));
    setShowDropdown(false);
    const items = cinList
      .filter((cin) => cin.RefNo === refNo)
      .map((cin) => cin.Item);
    setFilteredItems([...new Set(items)]);
    setSelectedItem("");
    setQuantity("");
    setIsDownloadEnabled(false);
  };

  const handleItemSelect = (item) => {
    setSelectedItem(item);
    setQuantity("");
    setIsDownloadEnabled(false);
    // Normalize RefNo format for comparison
    const normalizedSelectedRefNo = selectedRefNo.replace(/-/g, "/");
    // Fetch Ref Part No for the selected item
    const selectedCin = cinList.find(
      (cin) => cin.RefNo === normalizedSelectedRefNo && cin.Item === item
    );
    if (selectedCin) {
      setRefPartNo(selectedCin["Ref Part No"]);
    } else {
      setRefPartNo("");
    }
  };

  const handleQuantityChange = (event) => {
    const value = event.target.value;
    if (value === "") {
      setQuantity("");
      setIsDownloadEnabled(false);
    } else {
      const numericValue = Math.min(Number(value), 10000);
      setQuantity(numericValue);
      setIsDownloadEnabled(numericValue > 0);
    }
  };

  const handleBatchPrintChange = (event) => {
    setIsBatchPrinting(event.target.checked);
    setBatchPrintQty("");
  };

  const handleBatchPrintQtyChange = (event) => {
    const value = event.target.value;
    if (value <= quantity) {
      setBatchPrintQty(value);
    } else {
      alert("Batch quantity cannot be greater than the total quantity.");
    }
  };

  const handleFileTypeChange = (event) => {
    setFileType(event.target.value);
  };

  // ---------------- Code Generation for Download (Existing) ----------------
  const generateHexValues = (count) => {
    const hexValues = new Set();
    while (hexValues.size < count) {
      const randomID = Math.random().toString(36).substr(2, 6).toUpperCase();
      hexValues.add(randomID);
    }
    return Array.from(hexValues);
  };

  // ---------------- Helper Functions ----------------

  /**
   * Converts a data URL to a Blob.
   * @param {string} dataUrl - The data URL to convert.
   * @returns {Blob} - The resulting Blob.
   */
  const dataURLToBlob = (dataUrl) => {
    const arr = dataUrl.split(",");
    const mimeMatch = arr[0].match(/:(.*?);/);
    const mime = mimeMatch ? mimeMatch[1] : "";
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  /**
   * Generates a QR code with an embedded image.
   * @param {string} text - The text to encode in the QR code.
   * @param {string} embedImageURL - The URL of the image to embed.
   * @param {number} embedSize - The size of the embedded image in pixels.
   * @returns {Promise<string>} - A promise that resolves to a data URL of the generated QR code.
   */
  const generateQRCodeWithEmbed = async (
    text,
    embedImageURL,
    embedSize = 80 // Increased from 41 to 80
  ) => {
    try {
      // Generate QR code as data URL
      const qrDataUrl = await QRCode.toDataURL(text, {
        errorCorrectionLevel: "H",
        type: "image/png",
        width: 300,
        margin: 1,
      });

      // Create an off-screen canvas
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const qrImage = new Image();
      qrImage.src = qrDataUrl;

      await new Promise((resolve, reject) => {
        qrImage.onload = () => {
          canvas.width = qrImage.width;
          canvas.height = qrImage.height;
          ctx.drawImage(qrImage, 0, 0);
          resolve();
        };
        qrImage.onerror = reject;
      });

      // Load the embed image
      const embedImage = new Image();
      embedImage.src = embedImageURL;

      await new Promise((resolve, reject) => {
        embedImage.onload = () => {
          const centerX = canvas.width / 2 - embedSize / 2;
          const centerY = canvas.height / 2 - embedSize / 2;
          ctx.drawImage(embedImage, centerX, centerY, embedSize, embedSize);
          resolve();
        };
        embedImage.onerror = reject;
      });

      // Return the final QR code as data URL
      return canvas.toDataURL("image/png");
    } catch (error) {
      console.error("Error generating QR code with embed:", error);
      throw error;
    }
  };

  // ---------------- Updated Handler: Batch Codes Download (ZIP with QR Codes) ----------------
  const handleBatchDownload = async () => {
    const qty = Number(batchQuantity);
    const startValue = 248; // starting numeric portion
    const codes = Array.from({ length: qty }, (_, i) =>
      `https://media.arvo.services/?uid=1733937333669x${startValue + i}&d=${selectedBatch}`
    );

    try {
      const zip = new JSZip();

      // Create folders for PNG and BMP images
      const pngFolder = zip.folder("PNG");
      const bmpFolder = zip.folder("BMP");

      // ------------------ Generate QR Codes ------------------

      // Fetch the BMP image to embed in QR codes
      const embedImageResponse = await fetch(bmpImage);
      const embedImageBlob = await embedImageResponse.blob();
      const embedImageURL = URL.createObjectURL(embedImageBlob);

      // Define helper function to convert RGBA to RGB
      const getRGBData = (imageData) => {
        const { data, width, height } = imageData;
        const rgbData = new Uint8Array(width * height * 3);
        for (let i = 0, j = 0; i < data.length; i += 4, j += 3) {
          rgbData[j] = data[i];       // R
          rgbData[j + 1] = data[i + 1]; // G
          rgbData[j + 2] = data[i + 2]; // B
          // Ignore alpha channel
        }
        return { rgbData, width, height };
      };

      /**
       * Encodes RGB data to BMP format.
       * @param {number} width - Image width.
       * @param {number} height - Image height.
       * @param {Uint8Array} rgbData - RGB pixel data.
       * @returns {Blob} - BMP image as a Blob.
       */
      const encodeBMP = (width, height, rgbData) => {
        const rowSize = (3 * width + 3) & ~3; // Each row is padded to a multiple of 4 bytes
        const pixelArraySize = rowSize * height;
        const fileSize = 54 + pixelArraySize; // 54-byte header

        const buffer = new ArrayBuffer(fileSize);
        const view = new DataView(buffer);

        // BMP Header
        view.setUint8(0, 0x42); // 'B'
        view.setUint8(1, 0x4D); // 'M'
        view.setUint32(2, fileSize, true);
        view.setUint32(6, 0, true); // Reserved
        view.setUint32(10, 54, true); // Pixel data offset

        // DIB Header (BITMAPINFOHEADER)
        view.setUint32(14, 40, true); // Header size
        view.setUint32(18, width, true); // Image width
        view.setUint32(22, height, true); // Image height
        view.setUint16(26, 1, true); // Planes
        view.setUint16(28, 24, true); // Bits per pixel
        view.setUint32(30, 0, true); // Compression (0 = BI_RGB)
        view.setUint32(34, pixelArraySize, true); // Image size
        view.setUint32(38, 0, true); // X pixels per meter
        view.setUint32(42, 0, true); // Y pixels per meter
        view.setUint32(46, 0, true); // Total colors
        view.setUint32(50, 0, true); // Important colors

        // Pixel Data
        let offset = 54;
        for (let y = height - 1; y >= 0; y--) { // BMP stores pixels bottom-to-top
          for (let x = 0; x < width; x++) {
            const idx = (y * width + x) * 3;
            const r = rgbData[idx];
            const g = rgbData[idx + 1];
            const b = rgbData[idx + 2];
            view.setUint8(offset++, b);
            view.setUint8(offset++, g);
            view.setUint8(offset++, r);
          }
          // Padding for each row to be a multiple of 4 bytes
          const padding = (4 - (width * 3) % 4) % 4;
          for (let p = 0; p < padding; p++) {
            view.setUint8(offset++, 0);
          }
        }

        return new Blob([buffer], { type: "image/bmp" });
      };

      // Iterate over each code to generate QR codes
      for (let i = 0; i < codes.length; i++) {
        const code = codes[i];
        const qrFileName = `QR_Code_${i + 1}.png`;
        const bmpFileName = `QR_Code_${i + 1}.bmp`;

        // Generate QR code with embedded image (PNG)
        const qrWithEmbedDataUrl = await generateQRCodeWithEmbed(
          code,
          embedImageURL,
          80 // Increased embed size from 41 to 80
        );

        // Add PNG QR code to PNG folder
        pngFolder.file(qrFileName, qrWithEmbedDataUrl.split(",")[1], {
          base64: true,
        });

        // ------------------ Convert PNG to BMP ------------------

        // Create an Image object
        const qrImage = new Image();
        qrImage.src = qrWithEmbedDataUrl;

        await new Promise((resolve, reject) => {
          qrImage.onload = () => {
            try {
              // Create canvas
              const canvas = document.createElement("canvas");
              canvas.width = qrImage.width;
              canvas.height = qrImage.height;
              const ctx = canvas.getContext("2d");
              ctx.drawImage(qrImage, 0, 0);

              // Get image data
              const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

              // Convert RGBA to RGB
              const { rgbData, width, height } = getRGBData(imageData);

              // Encode to BMP
              const bmpBlob = encodeBMP(width, height, rgbData);

              // Read BMP Blob as ArrayBuffer
              const reader = new FileReader();
              reader.onload = () => {
                const arrayBuffer = reader.result;
                zip.file(bmpFileName, arrayBuffer);
                resolve();
              };
              reader.onerror = () => {
                console.error("Error reading BMP Blob.");
                reject(new Error("Error reading BMP Blob."));
              };
              reader.readAsArrayBuffer(bmpBlob);
            } catch (err) {
              console.error("Error processing BMP:", err);
              reject(err);
            }
          };
          qrImage.onerror = () => {
            console.error("Failed to load QR image for BMP conversion.");
            reject(new Error("Failed to load QR image for BMP conversion."));
          };
        });
      }

      // ------------------ Create CSV File ------------------

      // Add the CSV file to the root of the ZIP
      const currentDate = format(new Date(), "yyyy-MM-dd");
      const folderName = `${selectedBatch}-${currentDate}`;
      const csvFileName = `${folderName}.csv`;

      // Create CSV content
      const csvHeader = "Code\n";
      const csvContent = codes.join("\n");
      const csvString = csvHeader + csvContent;
      zip.file(csvFileName, csvString);

      // ------------------ Add Existing Images to ZIP ------------------

      // Fetch and add bmp_image.bmp
      const existingBmpResponse = await fetch(bmpImage);
      const existingBmpBlob = await existingBmpResponse.blob();
      zip.file("bmp_image.bmp", existingBmpBlob);

      // Fetch and add QR_Code.png
      const existingPngResponse = await fetch(pngImage);
      const existingPngBlob = await existingPngResponse.blob();
      zip.file("QR_Code.png", existingPngBlob);

      // ------------------ Generate and Download ZIP ------------------
      const zipBlob = await zip.generateAsync({ type: "blob" });
      saveAs(zipBlob, `${folderName}.zip`);

      // ------------------ Set Download Message ------------------
      setDownloadMessage(
        `The files are prepared for use with the following configuration:
Seed: 3230576506
CDP Size (mm): 5.3065996645686395
Black Ratio: 0.5
The CDP patterns are formatted to be centered as per the configuration provided.`
      );

      // ------------------ Cleanup ------------------
      URL.revokeObjectURL(embedImageURL);
    } catch (error) {
      console.error("Error during batch download:", error);
      alert("An error occurred while downloading batch codes.");
    }
  };

  // ---------------- Styles for Download Button & Inputs ----------------
  const downloadButtonStyles = {
    background: "linear-gradient(92.49deg, #0956CC 0%, #1A4E9F 100%)",
    boxShadow:
      "4px 4px 16px 0px #FFFFFF33 inset, -4px -4px 16px 0px #00000040 inset, 0px 4px 4px 0px #00000040",
    color: "white",
    padding: "10px 20px",
    fontSize: "16px",
    border: "none",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    height: "40px",
    borderRadius: "24px",
    fontFamily: "'Roboto', sans-serif",
    transition: "background-color 0.3s ease",
  };

  const inputStyle = {
    width: "100%",
    padding: "10px",
    marginBottom: "12px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    fontSize: "16px",
    fontFamily: "'Roboto', sans-serif",
  };

  const labelStyle = {
    fontWeight: "600",
    marginBottom: "6px",
    display: "block",
    fontFamily: "'Roboto', sans-serif",
  };

  return (
    <div
      style={{
        height: "100%",
        padding: "20px",
        backgroundColor: "#f9f9f9",
        marginBottom: "40px",
        border: "1px solid #ddd",
        borderRadius: "8px",
      }}
    >
      {/* ---------------- Batch Details Section ---------------- */}
      <div
        className="batch-selector-container"
        style={{ maxWidth: "500px", margin: "0 auto" }}
      >
        <h2
          className="batch-selector-title"
          style={{ marginBottom: "20px", textAlign: "center" }}
        >
          Batch Details
        </h2>
        <div className="form-group">
          <label style={labelStyle}>Batch Number:</label>
          <select
            value={selectedBatch}
            onChange={(e) => setSelectedBatch(e.target.value)}
            style={inputStyle}
          >
            <option value="">Select Batch Number</option>
            {batchNumbers.map((batch, index) => (
              <option key={index} value={batch}>
                {batch}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label style={labelStyle}>Batch Date:</label>
          <input
            type="date"
            value={batchDate}
            onChange={(e) => setBatchDate(e.target.value)}
            style={inputStyle}
          />
        </div>
        <div className="form-group">
          <label style={labelStyle}>Batch Quantity:</label>
          <input
            type="number"
            value={batchQuantity}
            onChange={(e) => setBatchQuantity(e.target.value)}
            min="1"
            style={inputStyle}
          />
        </div>
        {batchQuantity > 1 && (
          <div className="form-group" style={{ marginBottom: "20px" }}>
            <label style={{ ...labelStyle, fontWeight: "700" }}>
              Product Name:
            </label>
            <span style={{ fontSize: "16px", fontFamily: "'Roboto', sans-serif" }}>
              Metformin Hydrochloride Tablets
            </span>
          </div>
        )}
        {selectedBatch && batchDate && batchQuantity && (
          <button onClick={handleBatchDownload} style={downloadButtonStyles}>
            Download Batch Codes (ZIP with QR Codes)
          </button>
        )}
      </div>

      {/* ---------------- Display Download Message ---------------- */}
      {downloadMessage && (
        <div
          style={{
            marginTop: "20px",
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            backgroundColor: "#eaeaea",
            whiteSpace: "pre-line",
            fontFamily: "'Roboto', sans-serif",
            maxWidth: "500px",
            margin: "20px auto 0",
          }}
        >
          {downloadMessage}
        </div>
      )}
    </div>
  );
};

export default Morpen;
