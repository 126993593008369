import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css'; // Import the default styles for react-tabs
import PopupReview from './PopupReview'; // Import the popup component
import { ToastContainer } from 'react-toastify'; // Import ToastContainer
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify styles

const TicketsTable = () => {
  const [ticketIssues, setTicketIssues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0); // State for the selected tab
  const [selectedTicket, setSelectedTicket] = useState(null); // State for the selected ticket (for popup)

  const tenant = localStorage.getItem('tenant');

  useEffect(() => {
    if (tenant) {
      axios
        .get(`https://bxcswtw14g.execute-api.ap-south-1.amazonaws.com/prod?ticketsOwner=${tenant}`)
        .then((response) => {
          setTicketIssues(response.data.ticketIssues);
          setLoading(false);
        })
        .catch((err) => {
          setError('Failed to load ticket data');
          setLoading(false);
        });
    } else {
      setError('Tenant not found in localStorage');
      setLoading(false);
    }
  }, [tenant]);

  // Filter ticket issues based on the tab selected
  const filteredTickets = ticketIssues.filter((ticket) => {
    if (selectedTab === 0) return ticket.status === 'Open'; // Open tickets
    if (selectedTab === 1) return ticket.status === 'Closed'; // Closed tickets
    return true; // All tickets
  });

  const handleRowClick = (ticket) => {
    setSelectedTicket({ ...ticket, ticketsOwner: tenant }); // Add ticketsOwner to the ticket data
  };

  return (
    <div style={{ padding: '10px' }}>
      <ToastContainer /> {/* Add ToastContainer */}
      <h2 className="heading-label">Review Ticket</h2>
      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}
      {!loading && !error && (
        <>
          <Tabs selectedIndex={selectedTab} onSelect={(index) => setSelectedTab(index)}>
            <TabList style={{ width: '500px' }}>
              <Tab>Open</Tab>
              <Tab>Closed</Tab>
              <Tab>All</Tab>
            </TabList>

            {/* Open Tickets Tab */}
            <TabPanel>
              <table
                style={{
                  width: '100%',
                  borderCollapse: 'collapse',
                  marginBottom: '20px',
                }}
              >
                <thead style={{ borderBottom: '1px solid #0956cc', borderTop: '1px solid #0956cc' }}>
                  <tr>
                    <th style={{ padding: '12px 15px', textAlign: 'left', color: '#0956cc' }}>Issue</th>
                    <th style={{ padding: '12px 15px', textAlign: 'left', color: '#0956cc' }}>Phone Number</th>
                    <th style={{ padding: '12px 15px', textAlign: 'left', color: '#0956cc' }}>Status</th>
                    <th style={{ padding: '12px 15px', textAlign: 'left', color: '#0956cc' }}>Created On</th>
                    <th style={{ padding: '12px 15px', textAlign: 'left', color: '#0956cc' }}>Location</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredTickets.length > 0 ? (
                    filteredTickets.map((ticket, index) => (
                      <tr
                        key={index}
                        style={{ borderBottom: '1px solid #0956cc', cursor: 'pointer' }}
                        onClick={() => handleRowClick(ticket)} // Open popup on row click
                      >
                        <td style={{ padding: '12px 15px' }}>
                          {ticket.issueText && ticket.issueText.length > 20
                            ? ticket.issueText.substring(0, 20) + '...'
                            : ticket.issueText}
                        </td>
                        <td style={{ padding: '12px 15px' }}>{ticket.phonenumber}</td>
                        <td style={{ padding: '12px 15px' }}>{ticket.status}</td>
                        <td style={{ padding: '12px 15px' }}>{ticket.createdDate}</td>
                        <td style={{ padding: '12px 15px' }}>{ticket.city}, {ticket.region}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" style={{ padding: '12px 15px', textAlign: 'center', color: '#0956cc' }}>
                        No tickets available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </TabPanel>

            {/* Closed Tickets Tab */}
            <TabPanel>
              <table
                style={{
                  width: '100%',
                  borderCollapse: 'collapse',
                  marginBottom: '20px',
                }}
              >
                <thead style={{ borderBottom: '1px solid #0956cc', borderTop: '1px solid #0956cc' }}>
                  <tr>
                    <th style={{ padding: '12px 15px', textAlign: 'left', color: '#0956cc' }}>Issue</th>
                    <th style={{ padding: '12px 15px', textAlign: 'left', color: '#0956cc' }}>Phone Number</th>
                    <th style={{ padding: '12px 15px', textAlign: 'left', color: '#0956cc' }}>Status</th>
                    <th style={{ padding: '12px 15px', textAlign: 'left', color: '#0956cc' }}>Created On</th>
                    <th style={{ padding: '12px 15px', textAlign: 'left', color: '#0956cc' }}>Location</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredTickets.length > 0 ? (
                    filteredTickets.map((ticket, index) => (
                      <tr
                        key={index}
                        style={{ borderBottom: '1px solid #0956cc', cursor: 'pointer' }}
                        onClick={() => handleRowClick(ticket)} // Open popup on row click
                      >
                        <td style={{ padding: '12px 15px' }}>
                          {ticket.issueText && ticket.issueText.length > 20
                            ? ticket.issueText.substring(0, 20) + '...'
                            : ticket.issueText}
                        </td>
                        <td style={{ padding: '12px 15px' }}>{ticket.phonenumber}</td>
                        <td style={{ padding: '12px 15px' }}>{ticket.status}</td>
                        <td style={{ padding: '12px 15px' }}>{ticket.createdDate}</td>
                        <td style={{ padding: '12px 15px' }}>{ticket.city}, {ticket.region}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" style={{ padding: '12px 15px', textAlign: 'center', color: '#0956cc' }}>
                        No tickets available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </TabPanel>

            {/* All Tickets Tab */}
            <TabPanel>
              <table
                style={{
                  width: '100%',
                  borderCollapse: 'collapse',
                  marginBottom: '20px',
                }}
              >
                <thead style={{ borderBottom: '1px solid #0956cc', borderTop: '1px solid #0956cc' }}>
                  <tr>
                    <th style={{ padding: '12px 15px', textAlign: 'left', color: '#0956cc' }}>Issue</th>
                    <th style={{ padding: '12px 15px', textAlign: 'left', color: '#0956cc' }}>Phone Number</th>
                    <th style={{ padding: '12px 15px', textAlign: 'left', color: '#0956cc' }}>Status</th>
                    <th style={{ padding: '12px 15px', textAlign: 'left', color: '#0956cc' }}>Created On</th>
                    <th style={{ padding: '12px 15px', textAlign: 'left', color: '#0956cc' }}>Location</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredTickets.length > 0 ? (
                    filteredTickets.map((ticket, index) => (
                      <tr
                        key={index}
                        style={{ borderBottom: '1px solid #0956cc', cursor: 'pointer' }}
                        onClick={() => handleRowClick(ticket)} // Open popup on row click
                      >
                        <td style={{ padding: '12px 15px' }}>
                          {ticket.issueText && ticket.issueText.length > 20
                            ? ticket.issueText.substring(0, 20) + '...'
                            : ticket.issueText}
                        </td>
                        <td style={{ padding: '12px 15px' }}>{ticket.phonenumber}</td>
                        <td style={{ padding: '12px 15px' }}>{ticket.status}</td>
                        <td style={{ padding: '12px 15px' }}>{ticket.createdDate}</td>
                        <td style={{ padding: '12px 15px' }}>{ticket.city}, {ticket.region}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" style={{ padding: '12px 15px', textAlign: 'center', color: '#0956cc' }}>
                        No tickets available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </TabPanel>
          </Tabs>
        </>
      )}
      {selectedTicket && (
        <PopupReview
          ticketData={selectedTicket}
          onClose={() => setSelectedTicket(null)} // Close popup
        />
      )}
    </div>
  );
};

export default TicketsTable;
