import React, { useState, useEffect } from "react";
import "./Revenue.css";
import trendup from '../../../../assets/Trend Up.png';
import lineup from '../../../../assets/Line 2@2x.png';
import revenue from '../../../../assets/Revenue.png';
import sales from '../../../../assets/Sales.png';
import Modal from './Modal'; // Import the modal component

function Revenue() {
  const data1 = JSON.parse(localStorage.getItem('Brandlist')) || []; // Ensure it's an array even if null
  const data2 = JSON.parse(localStorage.getItem('userslist')) || {}; // Ensure it's an object even if null
  
  const [isBrandModalOpen, setBrandModalOpen] = useState(false);
  const [isUserModalOpen, setUserModalOpen] = useState(false);
  const [isOutreachModalOpen, setOutreachModalOpen] = useState(false); // Outreach modal state
  const [isNfcSessionsModalOpen, setNfcSessionsModalOpen] = useState(false); // NFC sessions modal state
  const [nfcsessions, setNfcSessions] = useState([]);
  const [uniqueRegions, setUniqueRegions] = useState(new Set());
  const [uniqueCities, setUniqueCities] = useState(new Set());
  const [uniqueCountries, setUniqueCountries] = useState(new Set());

  const lengthOfObject = Object.keys(data2).length; // Now safe to use Object.keys()

  // Fetch data from the API
  useEffect(() => {
    const fetchNfcSessions = async () => {
      try {
        const response = await fetch('https://nwtgrfetjc.execute-api.ap-south-1.amazonaws.com/prod/allnfcsession');
        const data = await response.json();
        
        if (data.Items) {
          setNfcSessions(data.Items);

          // Extract unique regions, cities, and countries
          const regions = new Set();
          const cities = new Set();
          const countries = new Set();

          data.Items.forEach(item => {
            regions.add(item.geodata.region);
            cities.add(item.geodata.city);
            countries.add(item.geodata.country);
          });

          // Update the states
          setUniqueRegions(regions);
          setUniqueCities(cities);
          setUniqueCountries(countries);
        }
      } catch (error) {
        console.error("Error fetching NFC sessions:", error);
      }
    };

    fetchNfcSessions();
  }, []);

  // Function to render data1 in a formatted way
  const renderBrands = () => {
    return data1.map((brand, index) => <li key={index}>{brand}</li>);
  };

  // Function to render data2 in a formatted way
  const renderUsers = () => {
    return Object.entries(data2).map(([brand, emails]) => (
      <div key={brand}>
        <h4>{brand}</h4>
        <ul>
          {emails.map((email, index) => (
            <li key={index}>{email}</li>
          ))}
        </ul>
      </div>
    ));
  };

  // Function to render outreach details
  const renderOutreachDetails = () => {
    return (
      <div>
        <h4>Outreach Information</h4>
        <p><strong>Unique Regions:</strong> {Array.from(uniqueRegions).join(', ')}</p>
        <p><strong>Unique Cities:</strong> {Array.from(uniqueCities).join(', ')}</p>
        <p><strong>Unique Countries:</strong> {Array.from(uniqueCountries).join(', ')}</p>
      </div>
    );
  };

  // Function to render NFC session details
  const renderNfcSessionsDetails = () => {
    return (
      <div>
        <h4>NFC Sessions</h4>
        <ul>
          {nfcsessions.map((session, index) => {
            const geodata = session.geodata || {}; // Fallback to an empty object
            const loc = geodata.loc || "Unknown Location";
            const ip = geodata.ip || "Unknown IP";
  
            return (
              <li key={index}>
                {session.title} - Location: {loc}, IP: {ip}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };
  

  return (
    <div style={{
      height: "190px",
      width: "100%",
      border: "none",
      borderRadius: "11px",
      padding: "10px",
      background: "white",
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    }}>
      <div style={{ display: "flex", gap: "10px" }}>
        {/* Total Brands Section */}
        <div style={{ width: '33%' }}>
          <div style={{ display: "flex", width: '100%', alignItems: "center" }}>
            <img src={revenue} style={{ width: '19px', marginRight: '5px' }} alt="" />
            <span className="revenue-header">Total brands</span>
          </div>
          <div>
            <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: 'center', marginTop: '30px' }}>
              <div style={{ marginLeft: "10px", display: 'flex', flexDirection: "column" }}>
                <span className="session-font-main">{data1.length}</span>
                <div style={{ display: "flex", alignItems: 'center' }}>
                  <img style={{ width: '16px' }} src={trendup} alt="" />
                </div>
                <span className="session-past-timeline" onClick={() => setBrandModalOpen(true)} style={{ cursor: "pointer" }}>
                  Check list
                </span>
              </div>
              <div>
                <img src={lineup} style={{ width: "40px" }} alt="" />
              </div>
            </div>
          </div>
        </div>

        {/* Total Users Section */}
        <div style={{ width: '33.5%' }}>
          <div style={{ display: "flex", width: '100%', alignItems: "center" }}>
            <img src={sales} style={{ width: '19px', marginRight: '5px' }} alt="" />
            <span className="revenue-header">Total Users</span>
          </div>
          <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: 'center', marginTop: '30px' }}>
            <div style={{ marginLeft: "10px", display: 'flex', flexDirection: "column" }}>
              <span className="session-font-main">{lengthOfObject}</span>
              <div style={{ display: "flex", alignItems: 'center' }}>
                <img style={{ width: '16px' }} src={trendup} alt="" />
                <span className="session-percent"></span>
              </div>
              <span className="session-past-timeline" onClick={() => setUserModalOpen(true)} style={{ cursor: "pointer" }}>
                Check list
              </span>
            </div>
            <div>
              <img src={lineup} style={{ width: "40px" }} alt="" />
            </div>
          </div>
        </div>

        {/* Total Scans Section */}
        <div style={{ width: '33%' }}>
          <div style={{ display: "flex", width: '100%', alignItems: "center" }}>
            <img src={sales} style={{ width: '19px', marginRight: '5px' }} alt="" />
            <span className="revenue-header">Total Scans</span>
          </div>
          <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: 'center', marginTop: '30px' }}>
            <div style={{ marginLeft: "10px", display: 'flex', flexDirection: "column" }}>
              <span className="session-font-main">{nfcsessions.length}</span>
              <div style={{ display: "flex", alignItems: 'center' }}>
                <img style={{ width: '16px' }} src={trendup} alt="" />
                <span className="session-percent">21%</span>
              </div>
              <span className="session-past-timeline" onClick={() => setNfcSessionsModalOpen(true)} style={{ cursor: "pointer" }}>
                Check list
              </span>
            </div>
            <div>
              <img src={lineup} style={{ width: "40px" }} alt="" />
            </div>
          </div>
        </div>

        {/* Outreach Section */}
        <div style={{ width: '33%' }}>
          <div style={{ display: "flex", width: '100%', alignItems: "center" }}>
            <img src={sales} style={{ width: '19px', marginRight: '5px' }} alt="" />
            <span className="revenue-header">Outreach</span>
          </div>
          <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: 'center', marginTop: '30px' }}>
            <div style={{ marginLeft: "10px", display: 'flex', flexDirection: "column" }}>
              <span className="session-font-main" style={{ fontSize: '14px', fontWeight: 'bold' }}>Unique Regions: {uniqueRegions.size}</span>
              <span className="session-font-main" style={{ fontSize: '15px', fontWeight: 'bold' }}>Unique Cities: {uniqueCities.size}</span>
              <span className="session-font-main" style={{ fontSize: '15px', fontWeight: 'bold' }}>Unique Countries: {uniqueCountries.size}</span>
              <div style={{ display: "flex", alignItems: 'center' }}>
                <img style={{ width: '16px' }} src={trendup} alt="" />
              </div>
              <span className="session-past-timeline" onClick={() => setOutreachModalOpen(true)} style={{ cursor: "pointer" }}>
                Check list
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Brand Modal */}
      <Modal
        isOpen={isBrandModalOpen}
        onClose={() => setBrandModalOpen(false)}
        title="Brand List"
        content={<ul>{renderBrands()}</ul>}
      />

      {/* User Modal */}
      <Modal
        isOpen={isUserModalOpen}
        onClose={() => setUserModalOpen(false)}
        title="User List"
        content={renderUsers()}
      />

      {/* Outreach Modal */}
      <Modal
        isOpen={isOutreachModalOpen}
        onClose={() => setOutreachModalOpen(false)}
        title="Outreach Information"
        content={renderOutreachDetails()}
      />

      {/* NFC Sessions Modal */}
      <Modal
        isOpen={isNfcSessionsModalOpen}
        onClose={() => setNfcSessionsModalOpen(false)}
        title="NFC Sessions"
        content={renderNfcSessionsDetails()}
      />
    </div>
  );
}

export default Revenue;
