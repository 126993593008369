import React, { useState } from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./FileUploader.css";

const FileUploader = () => {
  const [selectedOption, setSelectedOption] = useState("manual"); // 'manual' or 'batch'
  const [manualRefNo, setManualRefNo] = useState("");
  const [manualRefDate, setManualRefDate] = useState("");
  const [manualItem, setManualItem] = useState("");
  const [manualQuantity, setManualQuantity] = useState("");
  const [manualStatus, setManualStatus] = useState("");
  const [itemCategory, setItemCategory] = useState("");
  const [warehouse, setWarehouse] = useState("");
  const [refPartNo, setRefPartNo] = useState("");
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [showAdvancedFields, setShowAdvancedFields] = useState(false); // State to toggle advanced fields visibility

  const CHUNK_SIZE = 500; // Number of rows per chunk

  const downloadSampleFile = () => {
    const sampleData = [
      [
        "Sl No",
        "Item Category",
        "Warehouse",
        "RefNo",
        "RefDate",
        "New Part No",
        "Ref Part No",
        "Item",
        "UOM",
        "Supply Chain Receive Qty",
      ],
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(sampleData); // Convert data array to worksheet
    const workbook = XLSX.utils.book_new(); // Create a new workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sample"); // Add worksheet to workbook
    XLSX.writeFile(workbook, "SampleFile.xlsx"); // Trigger file download
  };

  // Manual entry form submission
  const handleManualSubmit = async (event) => {
    event.preventDefault();
    if (!manualRefNo || !manualRefDate || !manualItem || !manualQuantity || !refPartNo) {
      toast.error("Please fill all the fields, including Ref Part No.");
      return;
    }
  
    const apiBody = {
      RefNo: manualRefNo,
      RefDate: manualRefDate,
      Item: manualItem,
      Quantity: manualQuantity,
      'Ref Part No': refPartNo, // Include Ref Part No in API payload
      ItemCategory: itemCategory,
      Warehouse: warehouse,
    };
  
    try {
      await axios.post(
        "https://go83cmp8wk.execute-api.ap-south-1.amazonaws.com/dev/single-entry",
        apiBody
      );
      toast.success("Manual entry inserted successfully!");
      setManualRefNo("");
      setManualRefDate("");
      setManualItem("");
      setManualQuantity("");
      setItemCategory("");
      setWarehouse("");
      setRefPartNo("");
      setManualStatus("Manual entry inserted successfully!");
    } catch (error) {
      console.error("Error inserting manual entry:", error);
      setManualStatus("Failed to insert manual entry.");
      toast.error("Failed to insert manual entry.");
    }
  };
  

  // Handle file selection
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setUploadStatus("");
  };

  // Validate column headers in the XLSX file
  const validateFileHeaders = (headers) => {
    const requiredHeaders = ["RefNo", "Item", "RefDate"];
    const missingHeaders = requiredHeaders.filter(
      (header) => !headers.includes(header)
    );

    if (missingHeaders.length > 0) {
      missingHeaders.forEach((header) => {
        toast.error(`Missing column: ${header}`);
      });
      return false;
    }
    return true;
  };

  const uploadChunks = async (chunks, totalChunks) => {
    const batchId = Date.now(); // Unique batch identifier
    for (let chunkIndex = 0; chunkIndex < chunks.length; chunkIndex++) {
      const payload = {
        batchId,
        chunkData: chunks[chunkIndex],
        chunkIndex,
        totalChunks,
      };

      try {
        const response = await axios.post(
          "https://go83cmp8wk.execute-api.ap-south-1.amazonaws.com/dev/batch-upload",
          payload,
          {
            headers: { "Content-Type": "application/json" },
          }
        );

        console.log(
          `Chunk ${chunkIndex + 1} of ${totalChunks} uploaded successfully.`,
          response.data
        );
      } catch (error) {
        console.error(`Error uploading chunk ${chunkIndex + 1}:`, error);
        throw error;
      }
    }

    toast.success("All chunks uploaded successfully!");
  };

  // Handle file submission (batch upload)
  const handleFileSubmit = async (event) => {
    event.preventDefault();
    if (!file) {
      setUploadStatus("Please select a file first.");
      return;
    }

    setLoading(true);

    try {
      // Read and parse the XLSX file
      const fileData = await file.arrayBuffer();
      const workbook = XLSX.read(fileData, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

      // Validate column headers
      const headers = Object.keys(jsonData[0]);
      if (!validateFileHeaders(headers)) {
        setLoading(false);
        return; // Stop further processing if validation fails
      }

      // Split the data into chunks
      const chunks = [];
      for (let i = 0; i < jsonData.length; i += CHUNK_SIZE) {
        const chunk = jsonData.slice(i, i + CHUNK_SIZE);
        chunks.push(btoa(JSON.stringify(chunk))); // Encode each chunk as Base64
      }

      await uploadChunks(chunks, chunks.length);

      setUploadStatus("File uploaded successfully!");
      setLoading(false);
    } catch (error) {
      console.error("Upload error:", error);
      setUploadStatus("Failed to upload file.");
      setLoading(false);
      toast.error("Failed to upload file.");
    }
  };

  return (
    <div className="file-uploader">
      <h2>Supply Chain Management</h2>
      <p className="instruction-text">Manage your supply chain data below:</p>

      {/* Radio Button for Toggle */}
      <div className="toggle-container">
        <label className="toggle-label">
          <input
            type="radio"
            value="manual"
            checked={selectedOption === "manual"}
            onChange={() => setSelectedOption("manual")}
          />
          Manual Entry
        </label>
        <label className="toggle-label">
          <input
            type="radio"
            value="batch"
            checked={selectedOption === "batch"}
            onChange={() => setSelectedOption("batch")}
          />
          Batch Upload
        </label>
      </div>

      <div className="entry-sections">
        {/* Manual Entry Section */}
        <div className={`manual-entry-section ${selectedOption !== "manual" ? "inactive" : ""}`}>
  <h3>Insert Single Entry</h3>
  <form onSubmit={handleManualSubmit} className="manual-form">
    <label className="manual-label">
      Ref No:
      <input
        type="text"
        value={manualRefNo}
        onChange={(e) => setManualRefNo(e.target.value)}
        className="manual-input"
        disabled={selectedOption !== "manual"}
      />
    </label>
    <label className="manual-label">
      Ref Date:
      <input
        type="date"
        value={manualRefDate}
        onChange={(e) => setManualRefDate(e.target.value)}
        className="manual-input"
        disabled={selectedOption !== "manual"}
      />
    </label>
    <label className="manual-label">
      Item:
      <input
        type="text"
        value={manualItem}
        onChange={(e) => setManualItem(e.target.value)}
        className="manual-input"
        disabled={selectedOption !== "manual"}
      />
    </label>

    {/* Ref Part No Field - Moved Above Quantity */}
    <label className="manual-label">
      Ref Part No: <span style={{ color: "red" }}>*</span>
      <input
        type="text"
        value={refPartNo}
        onChange={(e) => setRefPartNo(e.target.value)}
        className="manual-input"
        disabled={selectedOption !== "manual"}
      />
    </label>

    <label className="manual-label">
      Quantity:
      <input
        type="number"
        value={manualQuantity}
        onChange={(e) => setManualQuantity(e.target.value)}
        className="manual-input"
        disabled={selectedOption !== "manual"}
      />
    </label>

    {/* Add Advanced Fields Button */}
    <button
      type="button"
      className="advanced-fields-button"
      onClick={() => setShowAdvancedFields((prev) => !prev)}
      style={{
        background: "linear-gradient(92.49deg, #0956CC 0%, #1A4E9F 100%)",
        color: "white",
        border: "none",
        padding: "5px 10px",
        borderRadius: "12px",
        width: "60%",
        cursor: "pointer",
        fontSize: "14px",
        transition: "0.3s",
      }}
    >
      {showAdvancedFields ? "- Remove" : "+ Add"} Advanced Fields
    </button>

    {/* Advanced Fields (Item Category, Warehouse) */}
    {showAdvancedFields && (
      <div className="advanced-fields">
        <label className="manual-label">
          Item Category:
          <input
            type="text"
            value={itemCategory}
            onChange={(e) => setItemCategory(e.target.value)}
            className="manual-input"
          />
        </label>
        <label className="manual-label">
          Warehouse:
          <input
            type="text"
            value={warehouse}
            onChange={(e) => setWarehouse(e.target.value)}
            className="manual-input"
          />
        </label>
      </div>
    )}

    <button
      type="submit"
      className="manual-button"
      disabled={selectedOption !== "manual"}
    >
      Insert Entry
    </button>
  </form>
  {manualStatus && <p className="status-message">{manualStatus}</p>}
</div>


        {/* Batch Upload Section */}
        <div
          className={`batch-upload-section ${
            selectedOption !== "batch" ? "inactive" : ""
          }`}
        >
          <h3>Batch Upload (XLSX File)</h3>
          <form onSubmit={handleFileSubmit} className="upload-form">
            <label className="file-label">
              <input
                type="file"
                accept=".xlsx"
                onChange={handleFileChange}
                className="file-input"
                disabled={selectedOption !== "batch"}
              />
              <span className="file-button">Choose File</span>
              {file && <span className="file-name">{file.name}</span>}
            </label>
            <button
              type="submit"
              className="upload-button"
              disabled={selectedOption !== "batch" || loading}
            >
              {loading ? (
                <span className="loading-spinner"></span>
              ) : (
                "Upload File"
              )}
            </button>
            {/* Separator for "or" with lines */}
            <div style={{display:"flex", justifyContent:"center" ,margin:"10px"}}>
            <div
              className="separatorline"
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0 10px",
                fontSize: "14px",
                color: "gray",
              }}
            >
              <div
                style={{
                  height: "1px",
                  width: "40px",
                  backgroundColor: "gray",
                  marginRight: "10px",
                }}
              ></div>
              <span>or</span>
              <div
                style={{
                  height: "1px",
                  width: "40px",
                  backgroundColor: "gray",
                  marginLeft: "10px",
                }}
              ></div>
              </div>
            </div>
            <button
              type="button"
              className="upload-button"
              style={{ backgroundColor: "gray" }}
              onClick={downloadSampleFile}
            >
              Download Sample File
            </button>
          </form>
          {uploadStatus && <p className="status-message">{uploadStatus}</p>}
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default FileUploader;
