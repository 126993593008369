import React, { useState, useRef, useEffect } from "react";
import Code from "../../../../assets/rename.png";
import DropArrow from "../../../../assets/dropdown.png";
import "./Scans.css";

interface ScansProps {
  nfcsessions: any[]; // Adjust the type according to the shape of nfcsessions data
}

function Scansgme({ nfcsessions }: ScansProps) {
  type ScanType = "G250" | "QR";

  const [totalCodeScans, setTotalCodeScans] = useState(0); // From gmelambdascan
  const [totalCodesGenerated, setTotalCodesGenerated] = useState(0); // From gmeuids
  const [scanType, setScanType] = useState<ScanType>("G250");
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const redirectToPricing = () => {
    const type = localStorage.getItem("subtype");
    if (type === "type2") {
      // Custom logic for type2
    } else {
      // window.location.href = "/pricing";
    }
  };

  const fetchData = async () => {
    try {
      // Fetch data for Total Code Scans
      const scansResponse = await fetch(
        "https://go83cmp8wk.execute-api.ap-south-1.amazonaws.com/dev/gmelambdascan"
      );
      const scansData = await scansResponse.json();
      setTotalCodeScans(scansData.length || 0);

      // Fetch data for Total Codes Generated
      const codesResponse = await fetch(
        "https://go83cmp8wk.execute-api.ap-south-1.amazonaws.com/dev/gmeuids"
      );
      const codesData = await codesResponse.json();
      setTotalCodesGenerated(codesData.length || 0);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setShowDropdown(false);
    }
  };

  const selectOption = (value: ScanType) => {
    setScanType(value);
    setShowDropdown(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="card card-two" onClick={() => redirectToPricing()}>
      <div className="scanner-btn-align">
        <div className="top-container">
          <img src={Code} alt="Scan icon" className="total-scans-icon" />
          <div className="total-scans-label">Drill Type</div>
        </div>
        <div className="dropdown" ref={dropdownRef} style={{ bottom: "20px" }}>
          <button
            className="dropdown-button"
            onClick={() => setShowDropdown(!showDropdown)}
          >
            {scanType} <img src={DropArrow} alt="Dropdown arrow" />
          </button>
          {showDropdown && (
            <div className="dropdown-content">
              <button onClick={() => selectOption("G250")}>G250</button>
              <button onClick={() => selectOption("QR")}>QR</button>
            </div>
          )}
        </div>
      </div>

      <div className="scanner-bottom-container">
        <div className="scan-category">
          <div className="scan-info">
            Total Codes Generated{" "}
            <span className="scan-number">{totalCodesGenerated}</span>
          </div>
          <div className="scan-bar-container">
            <div
              className="scan-bar"
              style={{
                width: `${
                  totalCodesGenerated
                    ? (totalCodesGenerated /
                        (totalCodesGenerated + totalCodeScans)) *
                      100
                    : 0
                }%`,
                backgroundColor: "#1A4E9F",
                height: `12px`,
              }}
            ></div>
          </div>
        </div>
      </div>

      <div className="scanner-bottom-container">
        <div className="scan-category">
          <div className="scan-info">
            Total Unique Code Scans{" "}
            <span className="scan-number">{totalCodeScans}</span>
          </div>
          <div className="scan-bar-container">
            <div
              className="scan-bar"
              style={{
                width: `${
                  totalCodeScans
                    ? (totalCodeScans /
                        (totalCodesGenerated + totalCodeScans)) *
                      100
                    : 0
                }%`,
                backgroundColor: "#119927",
                height: `12px`,
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Scansgme;
