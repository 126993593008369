import React, { useState, useEffect } from "react";
import axios from "axios";
import "./ProductReview.css";
import Sentiment from "./Sentiment";
import ReviewMap from "./ReviewMap";
import ReviewBreakdown from "./ReviewBreakdown";
import ReactApexChart from "react-apexcharts";
import ProgressBar from "./ProgressBar";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import WordCloudChart from "./WordCloudChart";
import CityReviewlist from "./CityReviewlist";
import CountryReviewlist from "./CountryReviewList";
import ReviewTicket from "./reviewticket/ReviewTicket";

function ProductReview({ id }) {
  const [reviewdata, setreviewdata] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Use default ID if undefined
        const finalId = id || "GS250 - Sinker Rock Drill";
        const response = await axios.get(
          `https://w770neckyl.execute-api.ap-south-1.amazonaws.com/prod/review/?id=${finalId}`
        );

        setreviewdata(response.data.Item);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);

  const [ratings, setratings] = useState([
    { stars: 5, percentage: 0 },
    { stars: 4, percentage: 0 },
    { stars: 3, percentage: 0 },
    { stars: 2, percentage: 0 },
    { stars: 1, percentage: 0 },
  ]);

  useEffect(() => {
    if (!reviewdata || !reviewdata.reviews) {
      return;
    }

    const starCounts = {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
    };

    reviewdata.reviews.forEach((review) => {
      const star = parseInt(review.star);
      if (star >= 1 && star <= 5) {
        starCounts[star]++;
      }
    });

    const totalReviews = reviewdata.reviews.length;

    const ratings = Object.keys(starCounts).map((star) => ({
      stars: parseInt(star),
      percentage: (starCounts[star] / totalReviews) * 100,
    }));

    setratings(ratings);
  }, [reviewdata]);

  const [selectcity, setselectcity] = useState({});
  const [selectcountry, setselectcountry] = useState({});

  useEffect(() => {
    if (!reviewdata || !reviewdata.reviews) {
      return;
    }

    const countrySentimentMap = {};
    const citySentimentMap = {};
console.log(reviewdata.reviews)
    reviewdata.reviews.forEach((review) => {
      if (review.location && review.location.country) {
        const country = review.location.country;
        const sentiment = review.Sentiment.Sentiment;

        if (country in countrySentimentMap) {
          countrySentimentMap[country].push(sentiment);
        } else {
          countrySentimentMap[country] = [sentiment];
        }
      }

      if (review.location && review.location.city) {
        const city = review.location.city;
        const sentiment = review.Sentiment.Sentiment;

        if (city in citySentimentMap) {
          citySentimentMap[city].push(sentiment);
        } else {
          citySentimentMap[city] = [sentiment];
        }
      }
    });

    setselectcity(citySentimentMap);
    setselectcountry(countrySentimentMap);
  }, [reviewdata]);

  const tenants = localStorage.getItem("tenant").toLowerCase();

  const percentages = ratings.map((val) => val.percentage);
  const series3 = percentages;
  const [options3] = useState({
    chart: {
      type: "donut",
    },
    legend: {
      show: false,
    },
    labels: ["Star-1", "Star-2", "Star-3", "Star-4", "Star-5"],
    responsive: [
      {
        options: {
          chart: {
            width: 100,
          },
        },
      },
    ],
  });
  const mapdata = [
    { x: 50, y: 100, color: "red", label: "Negative" },
    { x: 150, y: 100, color: "blue", label: "Neutral" },
    { x: 250, y: 100, color: "green", label: "Positive" },
  ];

  const [city, setcity] = useState();
  const [country, setcountry] = useState();

  useEffect(() => {
    if (selectcity) {
      const cityNames = Object.keys(selectcity);
      if (cityNames.length > 0) {
        setcity(cityNames[0]);
      }
    }

    if (selectcountry) {
      const countryNames = Object.keys(selectcountry);
      if (countryNames.length > 0) {
        setcountry(countryNames[0]);
      }
    }
  }, [selectcity, selectcountry]);

  const [showReviewModal, setShowReviewModal] = useState(false);

  const handleShowReviewModal = () => {
    setShowReviewModal(true);
  };

  const handleCloseReviewModal = () => {
    setShowReviewModal(false);
  };

  const getStarIcons = (numStars) => {
    const starIcons = [];
    for (let i = 0; i < numStars; i++) {
      starIcons.push("★");
    }
    return starIcons.join(" ");
  };

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }

  const type = localStorage.getItem("subtype");

  return (
    <div style={{ marginTop:'90px'}}>
      <span style={{ fontFamily: "Roboto", color: "#0956CC" }}>
        {type === 'type2' ? "" : "Upgrade your plan to gain comprehensive customer insights, connect with major marketplaces, and gather feedback from landing pages. Get detailed insights into your product's performance all in one place."}
      </span>
      <div className={`${type === 'type2' ? "" : 'blur-free-tier-content-tabs'}`} >
        <div
          className="productdetails-header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="productdetails-text">
            <p>Product Review</p>
          </div>
          <div>
            {showReviewModal && (
              <div className="modal">
                <div className="modal-content" style={{ minWidth: "800px" }}>
                  <span className="close" onClick={handleCloseReviewModal}>
                    &times;
                  </span>
                  <div
                    className="recent-reviews"
                    style={{ height: "500px", overflowY: "auto" }}
                  >
                    <h2 className="review-section-heading">Recent reviews</h2>

                    {reviewdata && reviewdata.reviews && reviewdata.reviews
                      .slice()
                      .reverse()
                      .map((review, index) => (
                        <div className="review-list" key={index}>
                          <div className="review-card">
                            <div
                              className="review-user"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "flex-start",
                              }}
                            >
                              <div>
                                {/* <img
                                  src={`https://randomuser.me/api/portraits/${index % 2 === 0 ? "women" : "men"}/${index}.jpg`}
                                  alt="User"
                                  className="user-avatar"
                                /> */}
                                <span className="user-name">{review.name}</span>
                                <br />
                                <span className="user-rating">
                                  {getStarIcons(review.star)}
                                </span>
                              </div>
                              <div
                                className="review-info"
                                style={{ textAlign: "right" }}
                              >
                                {review.location && review.location.city && (
                                  <div>
                                    <div
                                      className="review-city"
                                      style={{ textAlign: "left" }}
                                    >
                                      {review.location.city}
                                    </div>
                                    <div
                                      className="review-timestamp"
                                      style={{ textAlign: "left" }}
                                    >
                                      {formatTimestamp(review.timestamp)}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <p
                              className="review-text"
                              style={{ textAlign: "left" }}
                            >
                              {review.reviewText}
                            </p>
                            {review.location && review.location.city && (
                              <div
                                className="review-details"
                                style={{
                                  textAlign: "left",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                {/* <div className="sentiment-analysis">
                                  <h4>Sentiment Analysis:</h4>
                                  <ul>
                                    <li>
                                      Compound:{" "}
                                      {
                                        review.Sentiment.Sentiment.aggregate_sentiment
                                          .compound
                                      }
                                    </li>
                                    <li>
                                      Negative:{" "}
                                      {review.Sentiment.Sentiment.aggregate_sentiment.neg}
                                    </li>
                                    <li>
                                      Neutral:{" "}
                                      {review.Sentiment.Sentiment.aggregate_sentiment.neu}
                                    </li>
                                    <li>
                                      Positive:{" "}
                                      {review.Sentiment.Sentiment.aggregate_sentiment.pos}
                                    </li>
                                    <li>
                                      Sentiment: {review.Sentiment.Sentiment}
                                    </li>
                                  </ul>
                                </div> */}
                                <div
                                  className="content-info"
                                  style={{ textAlign: "right" }}
                                >
                                  <h4> Contant Info: </h4>
                                  {review.contactDetails}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            )}
            <button
              onClick={handleShowReviewModal}
              style={{
                background:
                  "linear-gradient(92.49deg, #0956CC 0%, #1A4E9F 100%)",
                boxShadow:
                  "4px 4px 16px 0px #FFFFFF33 inset, -4px -4px 16px 0px #00000040 inset",
                border: "none",
                height: "30px",
                color: "white",
                fontSize: "14px",
                fontWeight: "400",
                borderRadius: "24px",
                fontFamily: "Roboto",
                padding: "8px 16px",
                cursor: "pointer",
              }}
            >
              Show Reviews
            </button>
          </div>
        </div>

        <div className="review-page">
          <div className="review-container">
            <div className="review-data-container">
              {/* Analysis of review goes here */}
            </div>
          </div>
        </div>
        <div className="awesome-component">
          <div className="top-section">
            <div className="average-rating">
              <h2 className="heading-label">Average Rating and Breakdown</h2>
              <div className="chart-container">
                <div className="donut-chart" style={{ width: "50%" }}>
                  <ReactApexChart
                    options={options3}
                    series={series3}
                    type="donut"
                    height={170}
                  />
                </div>
                <div className="line-chart">
                  <div className="star-rating-list">
                    {ratings.map((rating, index) => (
                      <div
                        key={index}
                        className="star-rating-item"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        {rating.stars}
                        <div
                          className="star-rating-value"
                          style={{
                            height: "25px",
                            width: "25px",
                            margin: "3px",
                            alignItems: "center",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 16"
                            className="star-icon"
                          >
                            <linearGradient
                              id="grad"
                              x1="0%"
                              y1="0%"
                              x2="100%"
                              y2="100%"
                            >
                              <stop
                                offset="0%"
                                style={{ stopColor: "gold", stopOpacity: 1 }}
                              />
                              <stop
                                offset="100%"
                                style={{ stopColor: "orange", stopOpacity: 1 }}
                              />
                            </linearGradient>
                            <path
                              fill={`url(#grad)`}
                              d="M8 1.9l1.93 5.91h6.31l-5.14 3.73 1.93 5.9-5.14-3.73-5.14 3.73 1.93-5.9-5.14-3.73h6.31z"
                            />
                          </svg>
                        </div>

                        <ProgressBar
                          bgcolor="orange"
                          progress={rating.percentage}
                          height={10}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="response-time">
              <h2 className="heading-label">Review Sentiment</h2>
              <Sentiment reviewdata={reviewdata} />
            </div>
            {/* <div className="reviews-by-directory">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h2 className="heading-label">Reviews by City</h2>
                <select
                  value={city}
                  onChange={(event) => setcity(event.target.value)}
                  style={{
                    background:
                      "linear-gradient(92.49deg, #0956CC 0%, #1A4E9F 100%)",
                    boxShadow:
                      "4px 4px 16px 0px #FFFFFF33 inset, -4px -4px 16px 0px #00000040 inset",
                    border: "none",
                    height: "20px",
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "400",
                    borderRadius: "24px",
                    fontFamily: "Roboto",
                    padding: "8px 16px",
                    cursor: "pointer",
                  }}
                >
                  <div className="heading-label">City</div>
                  {Object.keys(selectcity).map((city) => (
                    <option key={city} value={city}>
                      {city}
                    </option>
                  ))}
                </select>
              </div>
              <div className="chart-container">
                <div className="donut-chart">
                  <CityReviewlist
                    reviewdata={reviewdata}
                    city={city}
                    selectcity={selectcity}
                  />
                </div>
              </div>
            </div>
            <div className="replies-and-breakdown">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h2 className="heading-label">Reviews by Country</h2>

                <select
                  value={country}
                  onChange={(event) => setcountry(event.target.value)}
                  style={{
                    background:
                      "linear-gradient(92.49deg, #0956CC 0%, #1A4E9F 100%)",
                    boxShadow:
                      "4px 4px 16px 0px #FFFFFF33 inset, -4px -4px 16px 0px #00000040 inset",
                    border: "none",
                    height: "20px",
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "400",
                    borderRadius: "24px",
                    fontFamily: "Roboto",
                    padding: "8px 16px",
                    cursor: "pointer",
                  }}
                >
                  <h3 className="heading-label">Country</h3>

                  {Object.keys(selectcountry).map((country) => (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ))}
                </select>
              </div>
              <div className="chart-container">
                <div className="pie-chart">
                  <CountryReviewlist
                    reviewdata={reviewdata}
                    country={country}
                    selectcountry={selectcountry}
                  />
                </div>
              </div>
            </div> */}
          </div>

          <div className="bottom-section">
        
            {/* <div className="ratings-and-reviews">
              <h2 className="heading-label">Ratings and Reviews Breakdown</h2>
              <ReviewBreakdown reviewdata={reviewdata} />
            </div> */}
            <div className="avg-rating-trend" style={{height:'400px'}}>
              <h2 className="heading-label">Review Word Cloud</h2>
              <WordCloudChart reviewdata={reviewdata} />
            </div>
          </div>

          <div className="big-bottom-component">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "-50px",
              }}
            >
              <h2 className="heading-label"> Review Map</h2>
              <svg width="350" height="150">
                {mapdata.map((point, index) => (
                  <circle
                    key={index}
                    cx={point.x}
                    cy={point.y}
                    r={5}
                    fill={point.color}
                  />
                ))}
                {mapdata.map((point, index) => (
                  <text
                    key={index}
                    x={point.x + 10}
                    y={point.y}
                    alignmentBaseline="middle"
                  >
                    {point.label}
                  </text>
                ))}
              </svg>
            </div>
            <ReviewMap reviewdata={reviewdata} />
          </div>
            <ReviewTicket />
        </div>
      </div>
    </div>
  );
}

export default ProductReview;
