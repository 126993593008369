import React, { useState } from "react";
import "./Tag.css";
import NFC213Image from "../../assets/ail.jpeg";
import NFC424Image from "../../assets/nxp-ntag213-nfc-tags-removebg-preview.png";
import NFCButtonImage from "../../assets/industrial-ip68-nfc-tags-removebg-preview.png";
import { loadStripe } from '@stripe/stripe-js';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import NotificationPopup from "./NotificationPopup"; 
import { fetchNfcSessions, fetchTenantData, setcredits } from "../../redux/counterSlice";

import axios from 'axios';
// type RowData = {
//   id: number;
//   nfc: string;
//   qty: number;
//   price: number;
// };

const nfcOptions = {
  NFC213: NFC213Image,
  NFC424: NFC424Image,
  NFCButton: NFCButtonImage,
};

function AiTags() {

  const [showNotification, setShowNotification] = useState(false); // State to manage visibility of notification popup

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const credits = useSelector((state)=> state.counter.credits)
  const [rows, setRows] = useState([
    { id: 1, nfc: "AI-Tags", qty: 1, price: 1 },
  ]);
  const [discount, setDiscount] = useState(10.0);
  const [tax, setTax] = useState(4.2);
  const [invoiceNumber, setInvoiceNumber] = useState(Date.now());
  const handleNfcChange = (id, newNfc) => {
    setRows(rows.map((row) => (row.id === id ? { ...row, nfc: newNfc } : row)));
  };

  const handleQtyChange = (id, newQty) => {
    setRows(rows.map((row) => (row.id === id ? { ...row, qty: newQty } : row)));
  };

  const handlePriceChange = (id, newPrice) => {
    setRows(
      rows.map((row) => (row.id === id ? { ...row, price: newPrice } : row))
    );
  };

  const deleteRow = (rowId) => {
    setRows(rows.filter((row) => row.id !== rowId));
  };

  // Include the addRow function inside the component
  const addRow = () => {
    const newRow = { id: rows.length-1, nfc: "AI-Tags", qty: 1, price: 1 };
    setRows([...rows, newRow]);
  };



  const getCurrentDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = today.getFullYear();
    return `${day}/${month}/${year}`;
  };
  // const odernow= async()=>{
  //   const postdata={
  //   rows,
  //   productName:JSON.parse(localStorage.getItem('productname')),
  //   productimage:JSON.parse(localStorage.getItem('productimage')),
  //   tenant:localStorage.getItem('tenant').toLowerCase()
  // }
  //     localStorage.setItem('itemsforinvoice',JSON.stringify(rows))

      

  //     const response = await fetch(
  //           'https://x5i01p9a23.execute-api.ap-south-1.amazonaws.com/prod/ordernow',
  //           {
  //             method: 'POST',
  //             headers: {
  //               'Content-Type': 'application/json'
  //             },
  //             body: JSON.stringify(postdata)
  //           }
  //         );
  //         const data = await response.json();
        

  //         await notificationSave();
  //         await creditupdate()


  // }


  const odernow = () => {
    toast.info('This is a Demo account, please contact support team', {
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  
  const notificationSave= async()=>{
    try {
      const postdata={
        employeeid:  localStorage.getItem('tenant').toLowerCase(),
        notification:{
          date: Date.now(),
          message:`Congratulations! Your order has been successfully placed. 🎉 
          Thank you for choosing our service! We'll take care of the rest and keep you updated on the progress. 
          If you have any questions or need assistance, feel free to reach out to us. `
        }
      }
       
      // Send POST request to your API endpoint
      const response = await axios.post("https://x5i01p9a23.execute-api.ap-south-1.amazonaws.com/prod/orderManagament", postdata);
  
    //  console.log("Response from API:", response.data);
     // Close the popup after successful submission
    } catch (error) {
      console.error("Error sending data:", error);
    }


  }

  const creditupdate = async()=>{
  
    if(total<=credits)
    {
      setShowNotification(true);
      toast.error('Not enough credits');
    dispatch(setcredits(credits-total));
    const newCreditHistoryData={
      date: Date.now(),
      creditused: total,
      nfc:total,
      productName:JSON.parse(localStorage.getItem('productname')),
      credit:'used nfc'
    }
    const postdata={
      employeeid:localStorage.getItem('tenant').toLowerCase(),
      credits:credits-total,
      newCreditHistoryData
  }
  
  try {
    const response = axios.put(
      "https://x5i01p9a23.execute-api.ap-south-1.amazonaws.com/prod/credits",
      JSON.stringify(postdata),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.data) {
    
      
   //   console.log("API call successful");
  
      // Set flag to prevent further API calls
    }
  } catch (error) {
    console.error(error);
  }
}
else{
  toast.error('Not enough credits');

} 
  
  }
  // const makepayment = async () => {
  //   const stripe = await loadStripe(
  //     'pk_test_51OrayqSGG9jIizTE2aObWHPzciXOS3xA9Hvhhp5JlITwu16GsnKXEoiMXnTpVzuBHR12avNR1bKJKDExmR04bq5j00IMyxlvPS'
  //   );

  // const postdata={
  //   rows,
  //   productName:JSON.parse(localStorage.getItem('productname')),
  //   productimage:JSON.parse(localStorage.getItem('productimage')),
  //   tenant:localStorage.getItem('tenant').toLowerCase()

  // }

  //   localStorage.setItem('itemsforinvoice',JSON.stringify(rows))
  //   const response = await fetch(
  //     'https://zda800k552.execute-api.ap-south-1.amazonaws.com/prod/checkout',
  //     {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify(postdata)
  //     }
  //   );
  //   const data = await response.json();
  //   console.log(data);
  //   stripe.redirectToCheckout({ sessionId: data.id });
  // };
  const subtotal = rows.reduce((prev, curr) => {
    if (rows.length > 0)
      return prev + Number(curr.price * Math.floor(curr.qty));
    else return prev;
  }, 0);
  const taxRate = (tax * subtotal) / 100;
  const discountRate = (discount * subtotal) / 100;
  const total = subtotal - discountRate + taxRate;
  return (
    <div className="tag-page" style={{width:'100%', backgroundColor: 'white', marginTop:'70px'}}>
         <ToastContainer />
      <div className="tag-box-container"  style={{width:'100%'}}>
        <div className="tag-box-upper-content">
          <div>
            <span style={{ fontWeight: "600" }}>Current Date: </span>
            <span>{getCurrentDate()}</span>
          </div>
          <div>
            <span style={{ fontWeight: "600" }}>Order Number: </span>
            <span>{invoiceNumber}</span>
          </div>
        </div>
        <div className="tag-horizontal-line"></div>
        <div className="tag-order-text">ORDER</div>
        <div className="tag-cashier-customer">
          <div>
            <span style={{ fontWeight: "600" }}>Cashier:&nbsp;</span>
            <span style={{ fontWeight: "600" }}>ARVO</span>
          </div>
          <div>
            <span style={{ fontWeight: "600" }}>Customer:&nbsp;</span>
            <span style={{ fontWeight: "600" }}>{localStorage.getItem('tenant')}</span>
          </div>
        </div>
        <div className="tag-order-table">
          <div className="tag-custom-table">
            <div className="tag-table-header">
              <div>AI-Tags</div>
              <div>QTY</div>
              <div>Credits</div>
              <div>Action</div>
            </div>
            <div className="table-tag-horizontal-line"></div>

            {rows.map((row) => (
              <div key={row.id} className="tag-table-row">
                <div className="nfc-first-column">
                  <img
                    src={NFC213Image}
                    alt={row.nfc}
                    style={{height:'34px', width:'35px' ,margin:'10px'}}
                    className="nfc-img"
                  />
                  <select
                    value={row.nfc}
                    onChange={(e) => handleNfcChange(row.id, e.target.value)}
                  >
                    <option value="NFC213">AI-Tags</option>
                    {/* <option value="NFCButton">Button Label</option> */}
                  </select>
                </div>
                <div>
                  <input
                    type="number"
                    value={row.qty}
                    onChange={(e) =>
                      handleQtyChange(row.id, Number(e.target.value))
                    }
                  />
                </div>
                <div>
                {row.price*row.qty}  
                
                  {/* <input
                    type="number"
                    value={row.price}
                    onChange={(e) =>
                      handlePriceChange(row.id, Number(e.target.value))
                    }
                  /> */}
                </div>
                <div className="nfc-table-delete-btn">
                  <button
                    onClick={() => deleteRow(row.id)}
                    className="delte-btn-table"
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </div>
          <button className="add-nfc-button" onClick={addRow}>
            Add AI-Lables
          </button>
        </div>
        <div className="order-placed-checkout">
          {/* <div className="checkout-item">
            <span style={{ fontWeight: "600" }}>Subtotal:</span>

            <span style={{ fontWeight: "300" }}>  
            {subtotal.toFixed(2)}
            &nbsp;
            <img width="15" height="15" src="https://img.icons8.com/ios/50/000000/get-revenue.png" alt="get-revenue"/>
             </span>
          </div> */}
          {/* <div className="checkout-item">
            <span style={{ fontWeight: "600" }}>Discount:</span>
            <span style={{ fontWeight: "300" }}><img width="15" height="15" src="https://img.icons8.com/ios/50/000000/get-revenue.png" alt="get-revenue"/>
            (10%) {discountRate.toFixed(2)}</span>
          </div> */}
          {/* <div className="checkout-item">
            <span style={{ fontWeight: "600" }}>Tax:</span>
            <span style={{ fontWeight: "300" }}>
            <img width="15" height="15" src="https://img.icons8.com/ios/50/000000/get-revenue.png" alt="get-revenue"/>
              (4.2%) {taxRate.toFixed(2)}</span>
          </div> */}
          <div className="checkout-line"></div>
          <div className="checkout-total">
            <span style={{ fontWeight: "600" }}>Total:</span>
            <span style={{ fontWeight: "600" }}>
              {subtotal.toFixed(2)} &nbsp;
              <img width="15" height="15" src="https://img.icons8.com/ios/50/000000/get-revenue.png" alt="get-revenue"/>
              </span>
          </div>
        </div>
      </div>
      <div className="tag-pymt">
        <button className="tag-payment-btn" onClick={odernow}>Order Now</button>
      </div>
      {showNotification && (
        <NotificationPopup
          message="Congratulations! Your order has been successfully placed. 🎉 
          Thank you for choosing our service! We'll take care of the rest and keep you updated on the progress. 
          If you have any questions or need assistance, feel free to reach out to us."
          onClose={() => setShowNotification(false)}
        />
      )}
    </div>
  );
}

export default AiTags;
