import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PopupReview = ({ ticketData, onClose }) => {
  const [status, setStatus] = useState(ticketData.status);

  const handleSave = async () => {
    if (status !== ticketData.status) {
      const payload = {
        createdDate: ticketData.createdDate,
        phonenumber: ticketData.phonenumber,
        status,
        ticketsOwner: ticketData.ticketsOwner,
      };
      try {
        await axios.post(
          'https://pqt417rv5k.execute-api.ap-south-1.amazonaws.com/prod/ticket-status',
          payload
        );
        // Show success toast
        toast.success("Status Changed! Please reload to see the change.");
      } catch (error) {
        console.error("Error updating status:", error);
        toast.error("Failed to update status. Please try again."); // Show error toast
      }
    }
    onClose();
  };
  
  

  return (
    <div style={styles.overlay}>
      <div style={styles.popup}>
        <button style={styles.closeButton} onClick={onClose}>
          ×
        </button>
        <h3>Ticket Details</h3>
        <div style={styles.form}>
          <div style={styles.formGroup}>
            <label>Issue Text</label>
            <textarea
              value={ticketData.issueText}
              readOnly
              style={{ ...styles.input, height: '100px', resize: 'none' }}
            />
          </div>
          <div style={styles.formGroup}>
            <label>Phone Number</label>
            <input type="text" value={ticketData.phonenumber} readOnly style={styles.input} />
          </div>
          <div style={styles.formGroup}>
            <label>Created Date</label>
            <input type="text" value={ticketData.createdDate} readOnly style={styles.input} />
          </div>
          <div style={styles.formGroup}>
            <label>City</label>
            <input type="text" value={ticketData.city} readOnly style={styles.input} />
          </div>
          <div style={styles.formGroup}>
            <label>Region</label>
            <input type="text" value={ticketData.region} readOnly style={styles.input} />
          </div>
          <div style={styles.formGroup}>
            <label>Status</label>
            <select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              style={styles.input}
            >
              <option value="Open">Open</option>
              <option value="Closed">Closed</option>
            </select>
          </div>
          <div style={styles.actions}>
            <button style={styles.button} onClick={handleSave}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  popup: {
    position: 'relative',
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    width: '400px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  },
  closeButton: {
    position: 'absolute',
    top: '6px',
    right: '10px',
    background: 'none',
    border: 'none',
    fontSize: '24px',
    fontWeight: 'bold',
    cursor: 'pointer',
    color: 'red',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  formGroup: {
    marginBottom: '15px',
  },
  input: {
    width: '100%',
    padding: '8px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    backgroundColor: '#0956cc',
    color: '#fff',
    border: 'none',
    padding: '10px 15px',
    borderRadius: '4px',
    cursor: 'pointer',
  },
};

export default PopupReview;
