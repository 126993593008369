import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Aztec.css';

const Aztec = ({ isOpen, onClose, skuid }) => {
  const [productSKUID, setProductSKUID] = useState(null);
  const [isSeparatePart, setIsSeparatePart] = useState(false);
  const [batchNumber, setBatchNumber] = useState('');
  const [unitNumber, setUnitNumber] = useState('');

  useEffect(() => {
    if (isOpen && skuid) {
      axios.get(`https://rll6gw1wbf.execute-api.ap-south-1.amazonaws.com/prod/product-skuid`, {
        params: { skuid: skuid }
      })
      .then(response => {
        const data = response.data;
        if (data.productSKUID) {
          setProductSKUID(data.productSKUID);
        }
      })
      .catch(error => {
        console.error("Error fetching productSKUID:", error);
      });
    }
  }, [isOpen, skuid]);

  const handleDownload = () => {
    // Define the payload according to the Lambda function requirements
    const payload = {
      ProductSKUID: productSKUID || skuid,
      BatchNumber: batchNumber,
      UnitNumber: unitNumber,
      isSeparatePart: isSeparatePart, // Include the checkbox state in the payload
    };
  
    // Add SKUID to the payload only if productSKUID is present, indicating the "update" case
    if (productSKUID) {
      payload.SKUID = skuid;
    }
  
    // Send the POST request to the API endpoint
    axios.post(`https://908cskqua4.execute-api.ap-south-1.amazonaws.com/prod/samsung-products`, payload)
      .then(response => {
        console.log("Aztec code operation successful:", response.data);
        alert(response.data.message);
  
        // Check if QR code was included in the response
        if (response.data.qrCode) {
          // Create a download link for the QR code
          const qrCodeBase64 = response.data.qrCode;
          const qrCodeUrl = `data:image/png;base64,${qrCodeBase64}`;
  
          // Create a link element and trigger a download
          const downloadLink = document.createElement("a");
          downloadLink.href = qrCodeUrl;
          downloadLink.download = `QR_Code_${productSKUID || skuid}.png`; // Filename for the downloaded image
          downloadLink.click();
        }
  
        onClose(); // Close the popup after successful submission
      })
      .catch(error => {
        console.error("Error with Aztec code operation:", error);
        alert("Failed to perform Aztec code operation.");
      });
  };
  
  if (!isOpen) return null;

  return (
    <div className="aztec-popup-overlay" onClick={onClose}>
      <div className="aztec-popup-content" onClick={(e) => e.stopPropagation()}>
        <h2>Download QR Code for your Product/Part</h2>
        
        <form className="aztec-form" onSubmit={(e) => e.preventDefault()}>
          <label>
            SKUID:
            <input type="text" name="skuid" value={skuid} readOnly />
          </label>
          {productSKUID && (
            <label>
              Product SKUID:
              <input type="text" name="productSKUID" value={productSKUID} readOnly />
            </label>
          )}
          <label>
            Batch Number:
            <input 
              type="text" 
              name="batchNumber" 
              value={batchNumber} 
              onChange={(e) => setBatchNumber(e.target.value)} 
            />
          </label>
          <label>
            Unit Number:
            <input 
              type="text" 
              name="unitNumber" 
              value={unitNumber} 
              onChange={(e) => setUnitNumber(e.target.value)} 
            />
          </label>
          {productSKUID && (
            <label className="checkbox-label">
              <input
                type="checkbox"
                checked={isSeparatePart}
                onChange={(e) => setIsSeparatePart(e.target.checked)}
              />
              To be sold as a separate part?
            </label>
          )}
          <button type="button" onClick={handleDownload}>Download QR Code</button>
        </form>

        <button className="close-btn" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default Aztec;
